'use client';
import { Flex } from '@gjensidige/builders-components';
import type { ArticleImagesProps as Props } from './types';
import styles from './article-images.module.css';

export const ArticleImages = ({ images }: Props) => (
  <Flex
    center
    className={styles.marginYParts}
    layout={{ xs: 1, md: 2 }}
    justify="end"
    gap="lg xl"
  >
    {images?.map((image, index) => (
      <div key={`${image?.url}-${index}`}>
        {image.url && (
          <figure className={styles.figure}>
            <img
              src={image.url}
              alt={image.alt ?? ''}
              height={image.height ?? 9}
              width={image.width ?? 16}
              srcSet={image.srcset}
              sizes={
                image.srcset &&
                `(min-width: 800px) and (max-width: 1023px) 50vw,
              (min-width: 1024px) and (max-width: 1619px) 600px,
              (min-width: 1620px) 800px,
              100vw`
              }
              loading="lazy"
            />
            {!!image?.caption && (
              <figcaption className={styles.figcaption}>
                {image.caption}
              </figcaption>
            )}
          </figure>
        )}
      </div>
    ))}
  </Flex>
);
