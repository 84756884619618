'use client';
import { useEffect, useRef, useState } from 'react';
import { Play, Pause } from '@gjensidige/builders-icons';
import {
  Button,
  Flex,
  Text,
  Title,
  ScreenReaderOnly
} from '@gjensidige/builders-components';
import type { HeaderCampaignProps as Props } from './types';
import styles from './header-campaign.module.css';
import clsx from 'clsx';

const IMAGE_SRC = `(min-width: 1024px) and (max-width: 1199px) 1024px, (min-width: 1200px) and (max-width: 1619px) 1200px, (min-width: 1620px) 1620px, 100vw`;

export const HeaderCampaign = ({
  h1,
  buttons,
  image,
  preface,
  title,
  video
}: Props) => {
  const [play, setPlay] = useState(true);
  const [showVideo, setShowVideo] = useState(!!video);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const reduce = window.matchMedia?.('(prefers-reduced-motion: reduce)');
    const prefersReducedMotion = reduce?.matches;

    videoRef.current?.[play ? 'play' : 'pause']();
    setShowVideo(!prefersReducedMotion);
  }, [play, video]);

  return (
    <Flex
      as="header"
      className={clsx(styles.headerCampaign, styles.marginBHeaders)}
      layout="1"
    >
      {/* We need separate center <Flex> and layout <Flex> so hidden h1 does not affect layout */}
      {!!h1 && <ScreenReaderOnly as="h1">{h1}</ScreenReaderOnly>}
      <Flex gap="none" layout={{ xs: 1, lg: 2, xxl: 7.5 }}>
        {!!video && showVideo ? (
          <div className={styles.mediaWrapper}>
            <video
              aria-label={image?.alt || ''}
              autoPlay
              className={styles.media}
              loop
              muted
              playsInline
              preload="metadata"
              ref={videoRef}
            >
              <source src={video} type="video/mp4" />
            </video>
            <Button
              aria-hidden="true"
              data-theme="dark"
              className={styles.playPause}
              icon={play ? <Pause /> : <Play />}
              onClick={() => setPlay(!play)}
              variant="unset"
            />
          </div>
        ) : (
          !!image && (
            <img
              src={image.url}
              srcSet={image.srcset}
              alt={image.alt || ''}
              width={16}
              height={9}
              sizes={image.srcset && IMAGE_SRC}
              className={styles.media}
            />
          )
        )}

        <Flex layout="1" gap="sm" alignContent="end" className={styles.content}>
          <Title
            as={h1 ? 'h2' : 'h1'}
            className={styles.title}
            size="3"
            text={title}
            uppercase
          />
          {!!preface && (
            <Text
              as="div"
              className={styles.htmlArea}
              dangerouslySetInnerHTML={{ __html: preface }}
              size="large"
            />
          )}
          {!!buttons?.length && (
            <Flex gap={{ xs: 'xs sm', lg: 'xs md' }}>
              {buttons.map(({ text, url }, index) => (
                <Button
                  data-theme="dark"
                  href={url}
                  key={index}
                  variant={
                    buttons.length === 1
                      ? 'critical'
                      : index
                        ? 'secondary'
                        : 'primary'
                  }
                >
                  {text}
                </Button>
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
