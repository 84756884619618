'use client';
import {
  Button,
  Link,
  Text,
  Title,
  Flex
} from '@gjensidige/builders-components';
import type { ContactPartProps as Props } from './types';
import styles from './contact.module.css';
import clsx from 'clsx';

export const Contact = ({ anchor, title, text, button, members }: Props) => {
  if (!members.length) return null;
  const hasButton = !!(button?.url && button?.text);
  const hasText = !!(title || text || hasButton);
  const isUnder = members.length > 2 || !hasText;

  return (
    <Flex
      align="start"
      center
      className={clsx(styles.marginYParts, styles.contactGap)}
      layout={{ xs: 1, md: isUnder ? 1 : 2 }}
      gap="xl"
      id={anchor}
    >
      {hasText && (
        <Flex layout={{ xs: 1, md: isUnder ? 2 : 1 }} gap="none xl">
          {!!title && <Title size="3" text={title} />}
          <Flex layout="1" gap="sm">
            {!!text && (
              <Text
                as="div"
                dangerouslySetInnerHTML={{ __html: text }}
                className={styles.htmlArea}
              />
            )}
            {hasButton && (
              <div>
                <Button href={button.url}>{button.text}</Button>
              </div>
            )}
          </Flex>
        </Flex>
      )}
      <Flex
        gap={{ xs: 'lg xl', md: 'xl' }}
        layout={{ xs: 1, lg: isUnder ? 2 : 1, xl: isUnder ? 3 : 1 }}
      >
        {members.map(({ photo, phone, email, team, name, title }, index) => (
          <Flex align="start" wrap="nowrap" key={index}>
            <div className={styles.photo}>
              {!!photo && <img alt={name} loading="lazy" src={photo} />}
            </div>
            <Flex layout="1" gap="none">
              <Title as="h3" size="5" text={name} />
              {title && <Text>{title}</Text>}
              {team && <Text>{team}</Text>}
              {phone && <Link href={`tel:${phone}`}>{phone}</Link>}
              {email && <Link href={`mailto:${email}`}>{email}</Link>}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
