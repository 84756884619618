import {
  Flex,
  Link,
  Text,
  Box,
  Tip as BuildersTip,
  Title
} from '@gjensidige/builders-components';

import {
  ContactProps,
  QuoteProps,
  InfoBoxTypes,
  ImageInfoProps,
  TipProps
} from './types';

import styles from './article-section.module.css';

export const Tip = ({ children, icon }: TipProps) => (
  <Flex layout={8.4}>
    <div>
      <BuildersTip icon={icon}>
        <Text
          as="div"
          className={styles.htmlArea}
          weight="500"
          dangerouslySetInnerHTML={{
            __html: typeof children === 'string' ? children : ''
          }}
        />
      </BuildersTip>
    </div>
  </Flex>
);

export const Quote = ({ byline, text }: QuoteProps) => (
  <Flex direction="column">
    <Text size="lead" className={styles.quote}>
      {text}
    </Text>
    {!!byline && <Title as="div" size="5" text={`- ${byline}`} />}
  </Flex>
);

export const Info = ({ title, text, links }: InfoBoxTypes) => (
  <Box variant="yellow" className={styles.info}>
    <Title as="h3" size="6" text={title} />
    {!!text && (
      <Text
        as="div"
        className={styles.htmlArea}
        dangerouslySetInnerHTML={{ __html: text }}
        size="small"
      />
    )}
    {links?.map(({ text, url }, index) => (
      <Link key={index} href={url} className={styles.infoLinks}>
        {text}
      </Link>
    ))}
  </Box>
);

export const Image = (image: ImageInfoProps) => (
  <figure className={styles.image}>
    <img
      src={image.url}
      alt={image.alt ?? ''}
      height={image.height}
      width={image.width}
      srcSet={image.srcset}
      loading="lazy"
    />
    <figcaption>{image.caption}</figcaption>
  </figure>
);

export const Contact = ({
  name,
  description,
  image,
  hideText = false
}: ContactProps) => (
  <Flex
    align="center"
    className={styles.contact}
    fill
    gap={{ xs: 'none sm', md: 'none xl' }}
    layout={2}
  >
    <img
      src={image.url}
      alt={name}
      height={250}
      width={250}
      sizes={image.srcset && '460px'}
      srcSet={image.srcset}
      loading="lazy"
    />
    {!hideText && (
      <div>
        <Title as="div" size="5" text={name} />
        <Text size="body">{description}</Text>
      </div>
    )}
  </Flex>
);
