'use client';
import clsx from 'clsx';
import {
  ActionList,
  Flex,
  Link,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { CtaGroupProps as Props } from './types';
import styles from './cta-group.module.css';

export const CtaGroup = ({
  heading,
  ctas,
  reverseSize = false,
  hideImages = false,
  optionalDescription
}: Props) => {
  const sliceIndex = ctas.length === 5 ? 2 : 4; // Determines the number of ctas in the top row

  return (
    <Flex layout={1} gap="sm none" center className={styles.marginYParts}>
      {hideImages ? (
        // Hide images
        <Flex layout={{ xs: 1, md: 2 }} gap="xs xl">
          <div>
            {!!heading && (
              <Title size="3" text={heading} className={styles.heading} />
            )}
            {!!optionalDescription && (
              <Text
                as="div"
                className={clsx(styles.htmlArea, styles.description)}
                dangerouslySetInnerHTML={{
                  __html: optionalDescription
                }}
                size="body"
              />
            )}
          </div>
          <ActionList aria-label={heading}>
            {ctas.map((cta, index) => (
              <ActionList.Link
                href={cta.link?.url}
                key={`${index}_${cta.title}`}
              >
                {cta.title}
              </ActionList.Link>
            ))}
          </ActionList>
        </Flex>
      ) : (
        // Show images
        <>
          {!!heading && <Title size="5" uppercase text={heading} />}
          <Flex layout={1} gap="xl none">
            {[ctas.slice(0, sliceIndex), ctas.slice(sliceIndex)].map(
              (splitCtas) => (
                <Flex align="start" gap="xl" layout={{ xs: 1, md: 2, lg: 4 }}>
                  {splitCtas.map(({ image, link, preface, title }, index) => (
                    <Flex
                      className={clsx(styles.card, {
                        [styles.isLarge]:
                          splitCtas.length < 3 ||
                          (splitCtas.length === 3 &&
                            index === (reverseSize ? 2 : 0))
                      })}
                      key={index}
                      layout={{ xs: 1, md: splitCtas.length === 1 ? 2 : 1 }}
                      align="center"
                      gap="sm xl"
                    >
                      {!!image && (
                        <figure className={styles.image}>
                          <img
                            alt={image.alt || ''}
                            height={9}
                            loading="lazy"
                            src={image.url}
                            srcSet={image.srcset}
                            width={16}
                          />
                        </figure>
                      )}
                      <Flex layout="1" gap="xs">
                        <Link
                          href={link?.url}
                          target={link?.newWindow ? '_blank' : undefined}
                          className={styles.title}
                          icon={false}
                        >
                          <Title
                            size="4"
                            as={preface ? (heading ? 'h3' : 'h2') : 'p'} // CTA-group has no preface, use p instead
                            text={title}
                          />
                        </Link>
                        {!!preface && <Text>{preface}</Text>}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              )
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
