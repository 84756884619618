'use client';
import { useTableData } from '../shared/utils';
import { useEffect, useState } from 'react';
import { Text, Title } from '@gjensidige/builders-components';
import { Button, Flex } from '@gjensidige/builders-components';
import type { InfographicsProps as Props } from './types';
import styles from './infographics.module.css';
import clsx from 'clsx';
import * as charts from './infographics-charts';

export const Infographics = (part: Props) => (
  <Flex
    center
    className={clsx(styles.items, styles.marginYParts)}
    fill
    gap="xl"
    layout={{ xs: 1, lg: 2, xl: 3 }}
    id={part.anchor || ''}
  >
    {!!part.title && <Title size={5} text={part.title} uppercase />}
    {part.charts?.map(function Item(chart, index, { length }) {
      const { Chart, props } = useChart(chart, part);
      const isNumber = chart.type === 'number';
      const isFlipped = part.layout === 'infographic' || isNumber;
      const text = part.large ? (length === 1 ? 'lead' : 'large') : 'body';
      const background = part.background || 'none';

      return (
        <Flex
          align={isNumber ? 'start' : 'center'} // Alignment when side by side
          alignContent={isFlipped ? 'start' : 'space-between'} // Alignment when stacked
          className={clsx(styles.item, styles[background])}
          gap={isNumber ? 'xs xl' : 'xl'}
          key={index}
        >
          {[
            <Flex key="text" layout="1" gap="xs" className={styles.content}>
              {!!chart.title && !isNumber && (
                <Title
                  as={part.title ? 'h3' : 'h2'}
                  size={part.large ? 2 : 4}
                  text={chart.title}
                />
              )}
              {!!chart.description && (
                <Text
                  as="div"
                  className={styles.htmlArea}
                  dangerouslySetInnerHTML={{ __html: chart.description }}
                  size={text}
                />
              )}
              {!!chart.footnotes && (
                <Text
                  as="div"
                  size="small"
                  className={styles.htmlArea}
                  dangerouslySetInnerHTML={{ __html: chart.footnotes }}
                />
              )}
              {!!chart.buttons?.length && (
                <Flex className={styles.buttons}>
                  {chart.buttons.map(({ text, url }, index) => (
                    <Button variant="action" href={url} key={index}>
                      {text}
                    </Button>
                  ))}
                </Flex>
              )}
            </Flex>,
            <div key="graphic">
              <div className={styles.scroller}>
                {!!Chart && <Chart {...props} ariaLabel={chart.ariaLabel} />}
              </div>
            </div>
          ][isFlipped ? 'reverse' : 'slice']()}
        </Flex>
      );
    })}
  </Flex>
);

type Types = keyof typeof charts;
type Chart = Exclude<Props['charts'], undefined>[number];

const useChart = (chart: Chart, part: Props) => {
  const table = useTableData(chart.data || '');
  const external = useExternal(chart);
  const type = `${chart.type?.[0].toUpperCase()}${chart.type?.slice(1)}`;
  const [head, ...rows] = [...(table?.head || []), ...(table?.body || [])]; // Merge thead and tbody as and just assume first tr is head row

  return {
    Chart: charts[type as Types],
    props: {
      part,
      rawData: chart.data,
      legends:
        external ||
        head?.slice(1).map(({ text: legend }, colIndex) => ({
          legend,
          values: rows.map(({ [colIndex + 1]: col }, index) => ({
            label: rows[index][0]?.text,
            text: col?.text || '',
            value: col?.number || 0,
            tooltip:
              col?.tooltip ||
              [rows[index][0].text, col?.text].filter(Boolean).join(': ') // Hide empty texts
          }))
        }))
    }
  };
};

const useExternal = (chart: Chart) => {
  const { dataSourceUrl: url, dataSourceMockJson: mock } = chart;
  const [data, setData] = useState();

  useEffect(() => {
    if (!url) return;
    fetch(mock ? '#' : url) // If mock, just request same url so we fake a delay
      .then((res) => (mock ? Promise.resolve(JSON.parse(mock)) : res.json()))
      .catch((err) => console.log(`Chart could not fetch url `, url, err))
      .then((data) =>
        setData(
          data.datasets.map((values: number[]) => ({
            values: values.map((value, index, { length }) => ({
              label: length > 10 ? null : data.labels[index],
              tooltip: `${data.labels[index]}: ${value}`,
              value
            }))
          }))
        )
      );
  }, [url, mock]);

  return data;
};
