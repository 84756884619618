'use client';
import {
  CtaBanner as BuildersCtaBanner,
  Flex,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { CtaBannerProps as Props } from './types';
import styles from '../shared/utils.module.css';

export const CtaBanner = ({
  title,
  text,
  link,
  symbol,
  backgroundColor = 'blue'
}: Props) => (
  <Flex center fill className={styles.marginYParts}>
    <BuildersCtaBanner
      variant={backgroundColor}
      href={link.url}
      target={link.newWindow ? '_blank' : undefined}
      rel="noreferrer"
    >
      {!!symbol && <img alt="" src={symbol} height="159" width="163" />}
      <Title text={title} />
      {!!text && (
        <BuildersCtaBanner.Content>
          <Text
            as="div"
            className={styles.htmlArea}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </BuildersCtaBanner.Content>
      )}
    </BuildersCtaBanner>
  </Flex>
);
