'use client';
import { useEffect } from 'react';
import { Button, Flex, Text, Title } from '@gjensidige/builders-components';
import { VideoProps as Props } from './types';
import styles from './video.module.css';

const QBIRCK_SCRIPT = {
  id: 'qplayer',
  src: 'https://play2.qbrick.com/qplayer/loader/qplayer-loader.js'
};
const YOUTUBE_ID_REGEX =
  /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^?&"'>]+)/; // From https://gist.github.com/afeld/1254889

export const Video = ({
  button,
  qbrickMediaId,
  reverseOrder,
  text,
  title,
  youtubeVideoLink: youtube
}: Props) => {
  const youTubeId = youtube?.slice(0, 300)?.match(YOUTUBE_ID_REGEX)?.[5]; // Slice to ensure we can not get a too ong string slowing down the regex

  useEffect(() => {
    if (document.getElementById(QBIRCK_SCRIPT.id)) return; // Only load Qbrick player once
    document.body.append(
      Object.assign(document.createElement('script'), QBIRCK_SCRIPT)
    );
  }, []);

  return (
    <Flex
      align="end"
      center
      className={styles.marginYParts}
      direction={reverseOrder ? 'row-reverse' : 'row'}
      gap={{ xs: 'sm md', md: 'sm xl' }}
      justify={reverseOrder ? 'start' : 'end'}
      layout={{ xs: 1, md: 2 }}
    >
      {!!title && !text && !button && <div /> /* Push content if only title */}
      {!!(title || text || button) && (
        <Flex layout="1" gap={{ xs: 'sm', md: 'sm' }}>
          {!!title && <Title size="4" text={title} />}
          {!!text && (
            <Text
              as="div"
              className={styles.htmlArea}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {!!button && (
            <div>
              <Button
                href={button.url}
                target={button.newWindow ? '_blank' : undefined}
              >
                {button.text}
              </Button>
            </div>
          )}
        </Flex>
      )}
      {qbrickMediaId ? (
        <div
          className={styles.videoContainer}
          data-qplayer-account-id="1583217"
          data-qplayer-autoplay="false"
          data-qplayer-media-id={qbrickMediaId}
          data-qplayer-mute="false"
          data-qplayer-preset-id="Enterprise"
        />
      ) : (
        youTubeId && (
          <div className={styles.videoContainer}>
            <iframe
              aria-label={title ? `Video: ${title}` : 'video'}
              src={`https://www.youtube.com/embed/${youTubeId}`}
            />
          </div>
        )
      )}
    </Flex>
  );
};
