'use client';
import { Button, Text, Title, Flex } from '@gjensidige/builders-components';
import type { TextSetsProps as Props } from './types';
import styles from './text-sets.module.css';

export const TextSets = ({ textSets, title, anchor }: Props) => {
  const lg = textSets.length === 1 ? 7.5 : textSets.length === 3 ? 3 : 2;

  return textSets?.length ? (
    <Flex
      align="start"
      center
      className={styles.marginYParts}
      gap="lg xl"
      layout={{ xs: 1, md: 2, lg }}
      id={anchor}
    >
      {!!title && (
        <Title className={styles.title} size="5" text={title} uppercase />
      )}
      {textSets.map(({ title: textSetTitle, text, buttons }, index) => (
        <Flex layout="1" key={index} gap="sm" className={styles.set}>
          <Title size="4" as={title ? 'h3' : 'h2'} text={textSetTitle} />
          <Text
            as="div"
            className={styles.htmlArea}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {!!buttons?.length && (
            <Flex>
              {buttons?.map((btn, index) => (
                <Button
                  key={index}
                  variant={index ? 'secondary' : 'primary'}
                  href={btn.url}
                  target={btn.newWindow ? '_blank' : undefined}
                  tracking={{
                    eventVersion: '1.0.0',
                    eventSource: '@gjensidige/parts-text-sets',
                    eventModule: 'text-sets',
                    eventLabel: btn.trackingLabel || `text-sets_${btn.url}`,
                    eventKey: 'button',
                    eventCategory: 'link',
                    eventAction: 'click'
                  }}
                >
                  {btn.text}
                </Button>
              ))}
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  ) : null;
};
