import { maybeHTMLorMarkdown } from '../../shared/utils.tsx';
import { SingleProductInsuranceProps } from '../../shared/types';
import clsx from 'clsx';
import { Expandable, Flex, Text, Title } from '@gjensidige/builders-components';

import styles from '../product-overview.module.css';

type Props = {
  product: SingleProductInsuranceProps;
  hideProductTitle: boolean;
};

export const Body = ({ product, hideProductTitle }: Props) => (
  <div
    className={clsx(styles.product, styles.columns, {
      [styles.singleFeature]: product?.features?.length === 1
    })}
  >
    {hideProductTitle || !product.name || (
      <Title
        as="h3"
        className={styles.productTitle}
        size="5"
        text={product.name}
      />
    )}
    {product?.features?.map(({ name, uvp }, index, all) =>
      all.length === 1 ? (
        <Flex layout="1" gap="xs" key={index}>
          <Text as={product.name ? 'h4' : 'h3'} className={styles.featureTitle}>
            {name}
          </Text>
          {maybeHTMLorMarkdown(uvp)}
        </Flex>
      ) : (
        <Expandable
          className={clsx(styles.expandable, {
            [styles.half]: index === Math.floor((all.length - 1) / 2)
          })}
          key={`${name}_${index}`}
          variant="line-white-yellow"
        >
          <Expandable.Title>{name}</Expandable.Title>
          <Expandable.Content>{maybeHTMLorMarkdown(uvp)}</Expandable.Content>
        </Expandable>
      )
    )}
  </div>
);
