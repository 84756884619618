'use client';
import { useEffect, useState } from 'react';
import { Title } from '@gjensidige/builders-components';
import { Footer as CoreFooter } from '@gjensidige/core-footer';
import {
  Email,
  Facebook,
  Instagram,
  Linkedin,
  Phone,
  PhoneCall,
  Twitter
} from '@gjensidige/builders-icons';
import type { FooterProps as Props, OptionalFooterApiUrl } from './types';
import '@gjensidige/core-footer/dist/style.css';

export const Footer = ({
  footerData
}: OptionalFooterApiUrl & JSX.IntrinsicElements['footer']) => {
  const {
    links,
    nav,
    homeUrl,
    social,
    contact,
    language = 'en'
  } = footerData as Props;
  const [currentPath, setCurrentPath] = useState(homeUrl);

  useEffect(() => {
    if (window && window.location && window.location.pathname) {
      setCurrentPath(window.location.pathname);
    }
  }, []);

  // sets custom navigation based on path
  let groups = undefined;
  if (nav?.local && nav.local.length > 0) {
    nav.local.forEach((path) => {
      if (
        currentPath?.startsWith(path.url) ||
        currentPath?.startsWith(`/azure-ad${path.url}`)
      ) {
        groups = path.groups;
      }
    });
  }

  if (groups === undefined) groups = nav?.global ?? [];

  return (
    <CoreFooter language={language}>
      {!!(groups && groups.length) &&
        groups.map(({ title, links }, index) => (
          <CoreFooter.Nav key={index}>
            <Title>{title}</Title>
            {links?.map(({ url, text, newWindow }, index) => (
              <CoreFooter.Item
                key={index}
                href={url}
                target={newWindow ? '_blank' : undefined}
                label={text}
              />
            ))}
          </CoreFooter.Nav>
        ))}

      <CoreFooter.Social className="footerSocial">
        {contact && (
          <>
            {contact.phone && (
              <CoreFooter.Item
                icon={<Phone />}
                href={`tel:${contact?.phone?.target}`}
                label={contact?.phone?.label}
              />
            )}
            {contact.callMe && (
              <CoreFooter.Item
                icon={<PhoneCall />}
                href={contact?.callMe?.url}
                label={contact?.callMe?.label}
                target="_blank"
              />
            )}
            {contact.email && (
              <CoreFooter.Item
                icon={<Email />}
                href={`mailto:${contact?.email?.target}`}
                label={contact?.email?.label}
              />
            )}
          </>
        )}
        {social && (
          <>
            {social.instagram && (
              <CoreFooter.Item
                icon={<Instagram />}
                href={social.instagram.url}
                label={social.instagram.label}
                target="_blank"
              />
            )}
            {social.linkedin && (
              <CoreFooter.Item
                icon={<Linkedin />}
                href={social.linkedin.url}
                label={social.linkedin.label}
                target="_blank"
              />
            )}
            {social.facebook && (
              <CoreFooter.Item
                icon={<Facebook />}
                href={social.facebook.url}
                label={social.facebook.label}
                target="_blank"
              />
            )}
            {social.twitter && (
              <CoreFooter.Item
                icon={<Twitter />}
                href={social.twitter.url}
                label={social.twitter.label}
                target="_blank"
              />
            )}
          </>
        )}
      </CoreFooter.Social>
      {!!links && (
        <CoreFooter.Extras>
          {links?.map(({ text, url, newWindow }, index) => (
            <CoreFooter.Item
              key={index}
              href={url}
              target={newWindow ? '_blank' : undefined}
              label={text || url}
            />
          ))}
        </CoreFooter.Extras>
      )}
    </CoreFooter>
  );
};
