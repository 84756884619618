'use client';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Button,
  Flex,
  Link,
  Spinner,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { FeedProps, FeedItemProps } from './types';
import styles from './feed.module.css';

const KEYS = ['title', 'target', 'date'];

export const Feed = ({ sections }: FeedProps) => (
  <Flex
    as="section"
    center
    className={styles.marginYParts}
    fill
    gap="xl"
    layout={{ xs: 1, md: 2, xl: 3 }}
  >
    {sections.map(function FeedSection(
      { button, title, externalDataSource: src, feedItems, isEvent, locale },
      index
    ) {
      const { items, isExternalError } = useData(src?.url || feedItems);
      const event = clsx({ [styles.event]: !src && isEvent });

      return isExternalError ? (
        <Text>{src?.errorMessage || 'Could not get data'}</Text>
      ) : (
        <Flex
          key={index}
          className={styles.section}
          direction="column"
          gap={{ xs: 'sm', md: 'md' }}
          align="start"
        >
          <Title size="3" text={title} />
          <div className={styles.items}>
            {!items.length && <Spinner size="lg" />}
            {items
              .slice(0, sections.length === 1 ? 4 : 2)
              .map(
                ({ date = '', target, title, newWindow, preface }, index) => (
                  <Flex
                    as={target ? Link : 'div'}
                    className={clsx(styles.item, event)}
                    gap="xs"
                    key={index}
                    layout="1"
                    {...{ href: target, target: newWindow && '_blank' }}
                  >
                    <Text as="time" size="small">
                      {getNiceDate(date, locale).slice(0, event ? 6 : 99)}
                    </Text>
                    <Title as="h3" size="5" text={title} />
                    {!!preface && (
                      <Text
                        as="div"
                        className={styles.htmlArea}
                        dangerouslySetInnerHTML={{ __html: preface }}
                      />
                    )}
                  </Flex>
                )
              )}
          </div>
          {!!button && (
            <div>
              <Button variant="action" href={button.url}>
                {button.text}
              </Button>
            </div>
          )}
        </Flex>
      );
    })}
  </Flex>
);

const useData = (url: string | FeedItemProps[] = []) => {
  const isExternal = typeof url === 'string';
  const [items, setItems] = useState<FeedItemProps[]>(isExternal ? [] : url);
  const [isExternalError, setIsExternalError] = useState(false);

  useEffect(() => {
    if (!isExternal) return;
    fetch(url)
      .then((res) => res.json())
      .then(({ result }) => {
        if (!Array.isArray(result) || !result.length)
          throw new Error('Invalid data: Expected non-empty an array.');

        const isValid = result.every((obj) => KEYS.every((key) => key in obj));
        if (!isValid)
          throw new Error(
            'Invalid data format: Each object in the array must have title, target, and date properties.'
          );

        setItems(result);
      })
      .catch((error) => {
        setIsExternalError(true);
        console.error('Error fetching external data', error);
      });
  }, [isExternal, url]);

  return { items, isExternalError };
};

const getNiceDate = (dateStr?: string, locale = 'default') => {
  const date = new Date(dateStr || '');
  return [
    `0${date.getDate()}`.slice(-2),
    date.toLocaleString(locale, { month: 'long' }),
    date.getFullYear()
  ].join(' ');
};
