'use client';
import { getCoverage } from '../product-group-table';
import { ProductInsuranceProps } from '../../shared/types';
import { Fragment, useState } from 'react';
import {
  Text,
  Title,
  Table,
  ScreenReaderOnly
} from '@gjensidige/builders-components';
import type { ProductGroupTableViewProps } from '../types';
import styles from '../product-group-table.module.css';

export const TableDesktop = ({
  features,
  footer,
  preface,
  products,
  title,
  hideTitle
}: ProductGroupTableViewProps) => (
  <Table className={styles.desktop} fixed>
    <ScreenReaderOnly as="caption">{title}</ScreenReaderOnly>
    <Table.Thead>
      <Table.Tr>
        <Table.Th scope="col">
          <div>
            {!hideTitle && !!title && <Title as="p" size="4" text={title} />}
            {!!preface && (
              <Text
                as="div"
                className={styles.htmlArea}
                dangerouslySetInnerHTML={{ __html: preface }}
              />
            )}
          </div>
        </Table.Th>
        {products?.map(({ name, uvp }, index: number) => (
          <Table.Th key={`${name}-${index}`} scope="col">
            <div>
              <Title as="p" size="5" text={name} />
              {uvp}
            </div>
          </Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>

    <Table.Tbody>
      {features.map(function Row({ name, uvp }, index: number) {
        const [isExpanded, setExpand] = useState(false);

        return (
          <Fragment key={`${name}-${index}`}>
            <Table.Tr onClick={() => setExpand(!isExpanded)}>
              <Table.Th scope="row">
                <Table.Expander expanded={isExpanded}>{name}</Table.Expander>
              </Table.Th>
              {products?.map(
                (product: ProductInsuranceProps, index: number) => {
                  const { text, icon, isCovered } = getCoverage(name, product);
                  return (
                    <Table.Td
                      key={`${product.name}-${index}`}
                      className={isCovered && styles.isCovered}
                    >
                      {text || icon}
                    </Table.Td>
                  );
                }
              )}
            </Table.Tr>
            <Table.Tr hidden={!isExpanded}>
              <Table.Td colSpan={(products?.length || 0) + 1}>{uvp}</Table.Td>
            </Table.Tr>
          </Fragment>
        );
      })}
    </Table.Tbody>
    {!!footer && (
      <Table.Tfoot>
        <Table.Tr>
          <Table.Td colSpan={(products?.length || 0) + 1}>{footer}</Table.Td>
        </Table.Tr>
      </Table.Tfoot>
    )}
  </Table>
);
