'use client';
import { Title, Flex, CtaBanner, Text } from '@gjensidige/builders-components';
import type { CtaCampaignProps as Props } from './types';
import styles from './cta-campaign.module.css';
import clsx from 'clsx';

export const CtaCampaign = ({
  alignment = 'left',
  backgroundColor: variant = 'yellow',
  image,
  target = '#',
  text,
  title
}: Props) => (
  <Flex
    center
    className={clsx(styles.ctaCampaign, styles.marginYParts)}
    layout="1"
    gap="none"
  >
    {!!image && (
      <img
        alt={image.alt || ''}
        loading="lazy"
        src={image.url}
        srcSet={image.srcset}
        sizes={
          image.srcset &&
          `(min-width: 1024px) and (max-width: 1199px) 1024px,
              (min-width: 1200px) and (max-width: 1619px) 1200px,
              (min-width: 1620px) 1620px,
              100vw`
        }
      />
    )}
    <CtaBanner
      className={clsx(styles[alignment], styles.banner)}
      variant={variant}
      href={target}
      size="sm"
    >
      <CtaBanner.Content>
        {!!title && <Title size="4" text={title} />}
        {!!text && (
          <Text
            as="div"
            className={styles.htmlArea}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </CtaBanner.Content>
    </CtaBanner>
  </Flex>
);
