import { useEffect, useState } from 'react';
import { Text, Tooltip } from '@gjensidige/builders-components';
import styles from './utils.module.css';

export function maybeHTMLorMarkdown(text?: string): JSX.Element | null {
  if (!text) return null;
  const isHTML = text.trim().startsWith('<') && text.trim().endsWith('>');

  return isHTML ? (
    <Text
      as="div"
      className={styles.htmlArea}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <Text as="div" className={styles.htmlArea}>
      <p
        dangerouslySetInnerHTML={{
          __html: text
            .replace(/__/gi, '&nbsp;')
            .replace(/--/gi, '&shy;')
            .replace(/</gi, '&lt;')
            .replace(/>/gi, '&gt;')
            .replace(/\*([^\s][^\\*]+?[^\s])\*/gi, '<strong>$1</strong>')
            .replace(/\n/gi, '<br />')
        }}
      />
    </Text>
  );
}

export const useTableData = (html: string) => {
  const [tableData, setTableData] = useState<ReturnType<typeof parseTable>>();
  useEffect(() => setTableData(parseTable(html)), [html]);
  return tableData;
};

const parseTable = (html: string) => {
  const table = getDOM(html).querySelector('table');
  const head = Array.from(table?.tHead?.rows || [], parseRow);
  const body = Array.from(table?.tBodies?.[0].rows || [], parseRow);
  return { head, body };
};

const parseRow = ({ cells }: HTMLTableRowElement) =>
  Array.from(cells, ({ colSpan, innerHTML, nodeName, rowSpan, scope }, key) => {
    const [__html, , tooltipHTML = ''] = innerHTML.split(/<(p|br)[^>]*>\s*\*/i);
    const tooltip = tooltipHTML && getDOM(tooltipHTML).textContent?.trim();
    const text = getDOM(__html).textContent?.trim() || '';
    let children = (
      <div className={styles.htmlArea} dangerouslySetInnerHTML={{ __html }} />
    );

    if (tooltip)
      children = (
        <Tooltip data-tooltip content={tooltip}>
          {children}
        </Tooltip>
      );

    return {
      nodeName,
      number: Number(text.replace(/\s/gm, '')) || 0,
      props: { key, children, colSpan, rowSpan, scope },
      text,
      tooltip
    };
  });

// eslint-disable-next-line react-refresh/only-export-components
let DOM: HTMLDivElement;
export const getDOM = (innerHTML: string) =>
  Object.assign((DOM = DOM || document.createElement('div')), { innerHTML });
