'use client';
import {
  Text,
  Title,
  Flex,
  ScreenReaderOnly
} from '@gjensidige/builders-components';
import type { HeaderBlogProps as Props } from './types';
import styles from './header-blog.module.css';

export const HeaderBlog = ({
  h1,
  title,
  preface,
  date,
  image,
  timestamp,
  imageCaption,
  editorName,
  layoutImageBottom
}: Props) => (
  <Flex
    as="header"
    center
    className={styles.marginBHeaders}
    direction={layoutImageBottom ? 'column' : 'column-reverse'}
    gap="xl"
  >
    {!!h1 && <ScreenReaderOnly as="h1">{h1}</ScreenReaderOnly>}
    <Flex layout="1" gap="xs">
      {!!(date || editorName) && (
        <Flex gap="none xl">
          {!!date && (
            <span itemProp="datePublished" content={timestamp}>
              {date}
            </span>
          )}
          {!!editorName && <span itemProp="author">{editorName}</span>}
        </Flex>
      )}
      <Flex layout={{ xs: 1, md: 2 }} gap="xs xl">
        {!!title && <Title as={h1 ? 'h2' : 'h1'} size="2" text={title} />}
        {!!preface && (
          <Text
            as="div"
            className={styles.htmlArea}
            dangerouslySetInnerHTML={{ __html: preface }}
            size="large"
          />
        )}
      </Flex>
    </Flex>
    {!!image && (
      <figure className={styles.image}>
        <img
          alt={image.alt || ''}
          height={9}
          src={image.url}
          width={16}
          srcSet={image.srcset}
          sizes={
            image.srcset &&
            `(min-width: 1024px) and (max-width: 1199px) 1024px, (min-width: 1200px) and (max-width: 1619px) 1200px, (min-width: 1620px) 1620px, 100vw`
          }
        />
        {!!imageCaption && (
          <Text as="figcaption" size="small">
            {imageCaption}
          </Text>
        )}
      </figure>
    )}
  </Flex>
);
