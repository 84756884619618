'use client';
import './navigation.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, Modal } from '@gjensidige/builders-components';
import { Lock } from '@gjensidige/builders-icons';
import { Nav } from '@gjensidige/core-navigation';
import { Search } from '../shared/search';
import { ApiToNavItems } from './components/apiToNavItems';

import type {
  NavMenu,
  NavProps,
  NavUserResponse,
  NavNotification
} from './types';

export const Navigation = ({
  userApiUrl = '/_/service/com.gjensidige.builders.theme/api-user',
  baseUrl = '/',
  menuData,
  currentPath = '/'
}: NavProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    actions = [],
    items = [],
    language = 'en',
    search,
    languageSwitch = undefined
  } = menuData || {};
  const [notifications, setNotifications] = useState({});
  const [userData, setUserData] = useState<NavUserResponse>({
    isAuthorized: false
  });
  const texts = useContext(Nav.language);

  // Recursively set isExpanded to items, but also actions to prevent item highlight if current url is action
  addIsExpandedToItems(currentPath, [...items, ...actions]);

  // Check sessionStorage for user data on initial render
  useEffect(() => {
    const sessionUser = getSessionStorageOrDefault('userData');
    setUserData((prev) => (sessionUser?.isAuthorized ? sessionUser : prev));
  }, []);

  // Fetch userData from backend to verify login state
  useEffect(() => {
    const updateUserData = async () => {
      try {
        const res = await fetch(userApiUrl, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Applikasjon: 'INTERNETT'
          }
        });

        if (!res.ok) {
          throw new Error('Error fetching user-data');
        }

        const responseBody = await res.json();
        setUserData(normalizeUserResponse(responseBody));
      } catch (err) {
        console.log('Error fetching user-data');
        setUserData({ isAuthorized: false });
      }
    };
    updateUserData();
    storeQueryParamsInSessionStorage();
  }, [userApiUrl]);

  useEffect(() => {
    if (userData.isAuthorized) {
      if (window && window.sessionStorage) {
        sessionStorage.setItem('userData', JSON.stringify(userData));
      }
      menuData?.notifications?.forEach((request) =>
        getNotifications(request).then((nextNotifications) => {
          setNotifications((prevNotifications) => ({
            ...prevNotifications,
            ...nextNotifications
          }));
        })
      );
    } else {
      try {
        window.sessionStorage.removeItem('userData');
      } catch (err) {
        // No sessionStorage
      }
    }
  }, [userData, menuData?.notifications]);

  const [showModal, setShowModal] = useState(false);
  const onSearchToggle = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (event.type === 'click' || (event as React.KeyboardEvent).key === ' ') {
      event.preventDefault();
      setShowModal(!showModal);
    }
  };

  const currentRootLang =
    languageSwitch !== undefined
      ? languageSwitch
          .map(({ url }) => url)
          .sort((a, b) => b.length - a.length) // Ensure shorter urls are put last to prevent false positives
          .find((url) => currentPath.startsWith(url))
      : '';

  return (
    <Nav
      homeUrl={menuData?.homeUrl}
      id="buildersPlatformMainNavigation"
      isLoggedIn={false}
      language={language}
      ref={ref}
      systemMessages={`${baseUrl}api-builders/system-messages`}
      systemMessagesFilter={{ application: 'public' }}
    >
      <ApiToNavItems
        currentPath={currentPath}
        homeUrl={menuData?.homeUrl}
        items={items}
        notifications={notifications}
      />
      <Nav.Search
        href={search?.action?.href}
        onClick={onSearchToggle}
        onKeyDown={onSearchToggle}
        role="button"
      />
      <Nav.Actions>
        <Nav.Cart />
        {/* Empty span fixes React hydration bug */}
        <span />
        <ApiToNavItems currentPath={currentPath} items={actions} />

        {!!languageSwitch && (
          <div className={'buildersPlatformNavigationLanguageSwitch'}>
            {languageSwitch.map((lang, index: number) => (
              <>
                <Link
                  href={lang.url}
                  key={`${lang.language}${index}`}
                  aria-current={currentRootLang === lang.url}
                >
                  {lang.language.toUpperCase()}
                </Link>
              </>
            ))}
          </div>
        )}
      </Nav.Actions>
      {languageSwitch === undefined &&
        menuData?.authentication?.loginUrl &&
        (userData.isAuthorized ? (
          <Nav.User
            label={userData.fullname}
            href={
              menuData?.authentication?.loggedInUrl ||
              menuData?.authentication?.loginUrl
            }
          />
        ) : (
          <Nav.User icon={<Lock />} href={menuData?.authentication?.loginUrl} />
        ))}
      {!!search && ( // Render inside <Nav> to be interactable also on mobile
        <Modal
          aria-label={texts.search || 'Search'}
          closeLabel={texts.close || 'Close'}
          onClose={() => setShowModal(false)}
          open={showModal}
          size="sm"
          variant="box-outerscroll"
        >
          <Search {...search} />
        </Modal>
      )}
    </Nav>
  );
};

// Utilities
function getSessionStorageOrDefault(key: string) {
  try {
    return JSON.parse(window.sessionStorage.getItem(key) || '');
  } catch (err) {
    return '';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function normalizeUserResponse(res: any): NavUserResponse {
  const userResponse = {
    isAuthorized: false,
    fullname: '',
    customerType: ''
  };

  // Normalize api-user/v1 used by NO
  try {
    if (res?.personalia && res?.identifikasjon) {
      const { partref } = res.identifikasjon;
      const { fornavn, etternavn } = res.personalia;
      if (fornavn && etternavn && partref) {
        return {
          isAuthorized: true,
          fullname: `${fornavn} ${etternavn}`,
          customerType: partref
        };
      }
    }
  } catch (err) {
    console.log(`failed to normalize api-user/v1 response`);
  }

  // Normalize web-api-sweden/customer for SE and api/user for DK
  try {
    const { name: fullname, customerType } = res;
    if (fullname) {
      return {
        isAuthorized: true,
        fullname,
        customerType
      };
    }
  } catch (err) {
    console.log(`failed to normalize web-api-sweden/customer response`);
  }
  return userResponse;
}

async function getNotifications({ headers = [], endpoint }: NavNotification) {
  const headersObject: Record<string, string> = {};
  const headersArray = Array.isArray(headers) ? headers : [headers];
  headersArray.forEach(({ key, value }) => (headersObject[key] = value));

  try {
    return await fetch(endpoint, {
      method: 'GET',
      credentials: 'include',
      headers: headersObject
    }).then((response) => response.json());
  } catch (e) {
    console.log(`Error fetching notifications from: ${endpoint}`);
    return {};
  }
}

function addIsExpandedToItems(currentPath: string, items: NavMenu['items']) {
  // Last item in the collection containing the path should be current, assuming shorter urls are earlier in order
  const hrefs = items?.map(({ href }) => href).filter(Boolean) as string[];
  const hrefsByAscendingLength = hrefs.sort((a, b) => a.length - b.length);
  const currentLevelPath = hrefsByAscendingLength.reduce((acc, href = '') => {
    return currentPath.startsWith(href) ? href : acc;
  }, currentPath);

  // Add highlight to items that either contain the current item, or the current url
  return items?.some((item): boolean => {
    return (item.isExpanded =
      addIsExpandedToItems(currentPath, item.children) ||
      currentLevelPath === item.href);
  });
}

function storeQueryParamsInSessionStorage() {
  try {
    const urlSearchParams = new URLSearchParams(location.search);
    const sessionStorageItems = sessionStorage.getItem('builders.queryParams');
    const queryParams = sessionStorageItems
      ? JSON.parse(sessionStorageItems)
      : {};
    urlSearchParams.forEach((value, key) => {
      queryParams[key] = value;
      //special functionality for sales norway, adding partner to session storage
      if (key === 'partner') {
        sessionStorage.setItem(
          'nettbutikk.partner',
          JSON.stringify({
            partner: value,
            source: 'PARAM'
          })
        );
      }
    });
    sessionStorage.setItem('builders.queryParams', JSON.stringify(queryParams));
  } catch (err) {
    // sessionStorage is full or URLSearchParams is not supported
  }
}
