'use client';
import { useContext, useEffect } from 'react';
import { trackView, TrackingContext } from '@gjensidige/core-analytics';
import {
  Button,
  Flex,
  Status,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { ErrorPageProps as Props } from './types';
import styles from '../shared/utils.module.css';

export const PageError = ({ title, text, type, buttons = [] }: Props) => {
  // Track page path on mount
  const { options: trackingOptions } = useContext(TrackingContext);
  useEffect(() => trackView({ ...trackingOptions }), [trackingOptions]);

  return (
    <Flex center className={styles.marginYParts}>
      <Status variant={type}>
        <Title as="h1" size="2" text={title} />
        <Text
          as="div"
          className={styles.htmlArea}
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ flex: '1 0 100%' }}
        />
        {buttons.map((button, index) => (
          <Button
            key={index}
            href={button.url}
            variant={index ? 'secondary' : 'primary'}
          >
            {button.text}
          </Button>
        ))}
      </Status>
    </Flex>
  );
};
