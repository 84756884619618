import { Chart, Title } from '@gjensidige/builders-components';
import type { InfographicsProps } from './types';
import styles from './infographics.module.css';
import clsx from 'clsx';

type ChartProps = {
  part?: InfographicsProps;
  rawData?: string;
  shade?: boolean;
  value?: string;
  variant?: Parameters<typeof Chart.Axis>[0]['variant'];
  legends?: {
    legend: string;
    values: {
      label: string;
      text: string;
      value: number;
      tooltip: string;
    }[];
  }[];
  ariaLabel?: string;
};

/**
 * Base functions to avoid duplicate code
 */
const Legends = ({ legends }: ChartProps) => (
  <Chart.Legends className={styles.legends}>
    {legends?.map(({ legend }, index) => {
      return !!legend && <Chart.Legend key={index}>{legend}</Chart.Legend>;
    })}
  </Chart.Legends>
);

const Bars = ({ legends, variant, ariaLabel }: ChartProps) => (
  <Chart aria-label={ariaLabel || ''}>
    <Chart.Axis variant={variant} className={styles.axis}>
      {legends?.[0]?.values.map(({ label }, index, { length }) => {
        const bars = legends?.map(({ values }, barIndex) => {
          const { tooltip, value } = values[index] || {};
          return <Chart.Bar key={barIndex} value={value} tooltip={tooltip} />;
        });

        if (length === 1) return bars; // Do not use Chart.AxisGroup if only one group
        return <Chart.AxisGroup key={index} label={label} children={bars} />;
      })}
    </Chart.Axis>
    <Legends legends={legends} />
  </Chart>
);

const Lines = ({ legends, shade, ariaLabel }: ChartProps) => (
  <Chart aria-label={ariaLabel || ''} className={styles.lines}>
    <Chart.Axis className={styles.axis}>
      {legends?.map(({ values }, index) => (
        <Chart.Line key={index} shade={shade}>
          {values.map(({ tooltip, value }, pointIndex) => (
            <Chart.LinePoint key={pointIndex} value={value} tooltip={tooltip} />
          ))}
        </Chart.Line>
      ))}
      {legends?.[0]?.values.map(
        ({ label }, pointIndex) =>
          !!label && <Chart.AxisGroup key={pointIndex} label={label} />
      )}
    </Chart.Axis>
    <Legends legends={legends} />
  </Chart>
);

/**
 * Actual chart types
 */
export const BarGroupedHorizontal = (props: ChartProps) => <Bars {...props} />;

export const BarStackedHorizontal = (props: ChartProps) => (
  <Bars {...props} variant="horizontal-stacked" />
);

export const BarGroupedVertical = (props: ChartProps) => (
  <Bars {...props} variant="vertical" />
);

export const BarStackedVertical = (props: ChartProps) => (
  <Bars {...props} variant="vertical-stacked" />
);

export const Line = (props: ChartProps) => (
  <Lines {...props} shade={props.legends?.length === 1} />
);

export const LineWithoutShade = (props: ChartProps) => (
  <Lines {...props} shade={false} />
);

export const Bubbles = ({ legends, ariaLabel }: ChartProps) => (
  <Chart aria-label={ariaLabel || ''} className={styles.bubbles}>
    <Chart.Bubbles>
      {legends?.map(({ values: [{ text, tooltip, value }] }, index) => (
        <Chart.Bubble key={index} value={value} tooltip={tooltip}>
          {text}
        </Chart.Bubble>
      ))}
    </Chart.Bubbles>
    <Legends legends={legends} />
  </Chart>
);

export const Doughnut = ({ legends, ariaLabel }: ChartProps) => (
  <Chart aria-label={ariaLabel || ''}>
    <Chart.Doughnut>
      {legends?.map(({ values: [{ tooltip, value }] }, index) => (
        <Chart.DoughnutSlice
          key={index}
          value={value}
          tooltip={tooltip}
          // Add lowercase tabindex for preact svg compability https://github.com/preactjs/preact/issues/1061
          // @ts-ignore
          tabindex="0"
        />
      ))}
    </Chart.Doughnut>
    <Legends legends={legends} />
  </Chart>
);

export const Percentage = ({ legends, ariaLabel }: ChartProps) => (
  <Chart aria-label={ariaLabel || ''}>
    <Chart.Percentage value={legends?.[0]?.values?.[0]?.value || 0} />
    <Legends legends={legends} />
  </Chart>
);

/**
 * Not really a Builders Components Chart, so we create it from scratch here
 */
export const Number = ({ rawData, part }: ChartProps) => (
  <Title
    as={part?.title ? 'h3' : 'h2'}
    size={part?.large ? 4 : 5}
    className={clsx(styles.number, styles.htmlArea, {
      [styles.large]: part?.large
    })}
    dangerouslySetInnerHTML={{
      __html:
        rawData
          ?.replace(/<p\b([^>]*)>/g, '') // Remove <p> as this will be rendered inside h2 or h3
          .split(/<\/p>|<br[^>]*>/) // Split by </p> or <br>
          .filter((line) => line.trim()) // Remove lines with only white space
          .map((line) => `<span>${line}</span>`) // Wrap each line with <span> so we can style this to fake line breaks
          .join('') || ''
    }}
  />
);
