'use client';
import { useState, useRef, useEffect } from 'react';
import { TrustpilotLogo, TrustpilotStars } from '../shared/trustpilot';
import { Play, Pause } from '@gjensidige/builders-icons';
import {
  Button,
  Flex,
  Link,
  ScreenReaderOnly,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { HeaderCtaProps as Props } from './types';
import styles from './header-cta.module.css';
import clsx from 'clsx';

export const HeaderCta = ({
  h1,
  buttons,
  image,
  layout = 'left',
  locale,
  partnerLogo,
  preface,
  stars,
  title,
  video
}: Props) => {
  const [play, setPlay] = useState(true);
  const [showVideo, setShowVideo] = useState(!!video);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const reduce = window.matchMedia?.('(prefers-reduced-motion: reduce)');
    const prefersReducedMotion = reduce?.matches;

    videoRef.current?.[play ? 'play' : 'pause']();
    setShowVideo(!prefersReducedMotion);
  }, [play, video]);

  return (
    <Flex as="header" center className={styles.marginBHeaders} layout="1">
      {/* We need separate center <Flex> and layout <Flex> so hidden h1 does not affect layout */}
      {!!h1 && <ScreenReaderOnly as="h1">{h1}</ScreenReaderOnly>}
      <Flex
        align="end"
        direction={layout === 'right' ? 'row-reverse' : 'row'}
        gap="lg xl"
        layout={{ xs: 1, md: layout === 'full' || (!image && !video) ? 1 : 2 }}
      >
        {!!video && showVideo ? (
          <div className={styles.mediaWrapper}>
            <video
              aria-label={image?.alt || ''}
              autoPlay
              className={clsx(styles.media, styles[layout])}
              loop
              muted
              playsInline
              preload="metadata"
              ref={videoRef}
            >
              <source src={video} type="video/mp4" />
            </video>
            <Button
              aria-hidden="true"
              data-theme="dark"
              className={styles.playPause}
              icon={play ? <Pause /> : <Play />}
              onClick={() => setPlay(!play)}
              variant="unset"
            />
          </div>
        ) : (
          !!image && (
            <img
              alt={image.alt || ''}
              className={clsx(styles.media, styles[layout])}
              height={9}
              src={image.url}
              srcSet={image.srcset}
              width={16}
              sizes={
                image.srcset &&
                `(min-width: 1200px) and (max-width: 1619px) 600px,
              (min-width: 1620px) 800px,
              100vw`
              }
            />
          )
        )}
        <Flex
          align="start"
          gap="sm xl"
          layout={{
            xs: 1,
            md: layout === 'full' || (!image && !video) ? 2 : 1
          }}
          justify="end"
        >
          <Flex layout="1" gap="xs">
            {!!partnerLogo && (
              <img
                alt={partnerLogo.alt || ''}
                className={styles.partnerLogo}
                src={partnerLogo.url}
                srcSet={partnerLogo.srcset}
              />
            )}
            <Title
              className={styles.title}
              size="2"
              as={h1 ? 'h2' : 'h1'}
              text={title}
            />
            {!!stars && (
              <Flex align="center" gap="sm">
                {!!stars.showStars && (
                  <TrustpilotStars score={stars.trustScore} />
                )}
                <Text
                  aria-label={`Trustpilot ${stars.trustScore} / 5`}
                  role="img" // To avoid screen reader announcing content
                  size="large"
                  weight={700}
                >
                  <span aria-hidden="true">
                    {stars.trustScore.toLocaleString(locale)} / 5
                  </span>
                </Text>
                <Link
                  href={stars.profileUrl}
                  icon={false}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <TrustpilotLogo />
                </Link>
              </Flex>
            )}
          </Flex>
          <Flex layout="1" gap="md">
            {!!preface && (
              <Text
                as="div"
                className={styles.htmlArea}
                dangerouslySetInnerHTML={{ __html: preface }}
                size="large"
              />
            )}
            {!!buttons?.length && (
              <Flex gap="xs sm">
                {buttons.map((btn, index) => (
                  <Button
                    href={btn.url}
                    target={btn.newWindow ? '_blank' : undefined}
                    rel="noreferrer"
                    key={index}
                    tracking={
                      !!btn.trackingLabel && { eventLabel: btn.trackingLabel }
                    }
                    variant={
                      buttons?.length === 1
                        ? 'critical'
                        : index
                          ? 'secondary'
                          : 'primary'
                    }
                  >
                    {btn.text}
                  </Button>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
