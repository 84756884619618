'use client';
import clsx from 'clsx';
import {
  Button,
  Expandable,
  Flex,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { FaqPartProps as Props } from './types';
import styles from './faq.module.css';

export const Faq = ({
  title,
  groups,
  alignFaqRight,
  actionButton,
  anchor
}: Props) => {
  const right = alignFaqRight && groups.length === 1;
  const md = groups.length === 1 ? 1 : 2;
  const lg = groups.length === 3 ? 3 : md;

  return (
    <Flex
      as="section"
      center
      className={styles.marginYParts}
      layout={{ xs: 1, md: right ? 2 : 1 }}
      justify="end"
      gap="xl"
      id={anchor}
    >
      <div>
        {!!title && (
          <Title className={styles.title} size="5" text={title} uppercase />
        )}
        <Flex gap="xl" layout={{ xs: 1, md, lg }}>
          {groups.map((group, index) => (
            <div
              key={index}
              className={clsx(styles.group, {
                [styles.columns]: !right && groups.length === 1
              })}
            >
              {!!group.label && (
                <Title as={title ? 'h3' : 'h2'} size="5" text={group.label} />
              )}
              {group.questions?.map((item, index, all) => (
                <Expandable
                  key={index}
                  className={clsx({
                    [styles.half]: index === Math.floor((all.length - 1) / 2)
                  })}
                >
                  <Expandable.Title>{item.question}</Expandable.Title>
                  <Expandable.Content>
                    <Text
                      as="div"
                      className={styles.htmlArea}
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    />
                    {item.button?.url && item.button?.text && (
                      <Button className={styles.button} href={item.button.url}>
                        {item.button.text}
                      </Button>
                    )}
                  </Expandable.Content>
                </Expandable>
              ))}
            </div>
          ))}
        </Flex>
        {!!actionButton && (
          <Button variant="action" href={actionButton.url}>
            {actionButton.text}
          </Button>
        )}
      </div>
    </Flex>
  );
};
