'use client';
import {
  Button,
  Flex,
  Link,
  Text,
  Title
} from '@gjensidige/builders-components';
import { Body } from './components/body';
import type { ProductOverviewProps as Props } from './types';
import styles from './product-overview.module.css';

export const ProductOverview = ({
  title,
  price,
  pricePeriod,
  preface,
  hideFirstProductTitle,
  link,
  links,
  products,
  anchor
}: Props) => {
  if (!products?.length) return null;
  return (
    <Flex
      as="section"
      center
      className={styles.marginYParts}
      layout="1"
      gap="none"
      id={anchor}
    >
      <Flex className={styles.header} gap="xs xl" justify="space-between">
        <Flex layout="1" gap="xs">
          {!!title && <Title size="4" text={title} />}
          {!!preface && <Text>{preface}</Text>}
        </Flex>
        {!!price && (
          <Flex
            align="baseline"
            className={styles.price}
            gap="none"
            wrap="nowrap"
          >
            <Title as="div" size="4" text={price} />
            <Text>/{pricePeriod ?? 'mnd'}</Text>
          </Flex>
        )}
      </Flex>

      {products.map((product, index) => (
        <Body
          key={`${product?.name}-${index}`}
          hideProductTitle={index === 0 && !!hideFirstProductTitle}
          product={product}
        />
      ))}

      <Flex
        className={styles.footer}
        align="center"
        gap="md xl"
        justify="space-between"
        wrap="wrap"
      >
        {!!links?.length && (
          <Flex gap="xs lg" layout={{ xs: 1, sm: 'auto' }}>
            {links?.map(({ text, url }, index: number) => (
              <div>
                <Link href={url} key={index}>
                  {text}
                </Link>
              </div>
            ))}
          </Flex>
        )}
        {!!(link?.url && link?.text) && (
          <Button
            href={link.url}
            tracking={
              link.trackingLabel
                ? { eventLabel: link.trackingLabel }
                : undefined
            }
          >
            {link.text}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
