import { Flex, Text, Title } from '@gjensidige/builders-components';
import type { ArchiveItemProps as Props } from '../types';
import styles from '../archive.module.css';

export const ArchiveItem = ({
  title,
  preface,
  target,
  date,
  newWindow,
  isEvent,
  locale
}: Props & { isEvent: boolean; locale?: string }) => {
  const { dayOfMonth, monthName } = getDayAndMonth(date, locale);

  return (
    <Flex align="center" className={styles.archiveItem}>
      {isEvent ? (
        <Flex gap="md" wrap="nowrap">
          <div className={styles.eventDateText}>
            <Text>{dayOfMonth}</Text>
            <Text size="small">{monthName.slice(0, 3).toUpperCase()}</Text>
          </div>
          <div className={styles.eventTextBlock}>
            <Title size="5" text={title} />
            <Text
              as="div"
              className={styles.htmlArea}
              dangerouslySetInnerHTML={{ __html: preface || '' }}
            />
          </div>
        </Flex>
      ) : (
        <a
          href={target}
          className={styles.link}
          target={newWindow ? '_blank' : undefined}
          rel="noreferrer"
        >
          {!!date && <Text size="small">{`${dayOfMonth}.${monthName}`}</Text>}
          <Title className={styles.feedTitle} size="5" text={title} />
          <Text size="small">{preface}</Text>
        </a>
      )}
    </Flex>
  );
};

const getDayAndMonth = (date?: string, locale?: string) => {
  const dateObject = new Date(date || 0);
  const dayOfMonth = `0${dateObject.getDate()}`.slice(-2);
  const monthName = dateObject.toLocaleString(locale ?? 'default', {
    month: 'short'
  });
  return { dayOfMonth, monthName };
};
