'use client';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Expandable, Flex, Link } from '@gjensidige/builders-components';
import { ToCProps as Props } from './types';
import styles from './toc.module.css';

export const Toc = ({ title, list }: Props) => {
  const [activeUrl, setActiveUrl] = useState('');
  const [width, setWidth] = useState(Infinity);
  const [openMobile, setOpenMobile] = useState(false);
  const [openDesktop, setOpenDesktop] = useState(true);

  const isMobile = width < 800;
  const handleResize = () => setWidth(window.innerWidth);

  useIntersectionObserver(list, setActiveUrl);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      setWidth(window.innerWidth);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <div className={styles.tocOuter}>
      <Flex layout="1" center>
        <div
          className={clsx(
            styles.tocInner,
            !openDesktop && styles.tocInnerClosed
          )}
        >
          <Expandable
            open={isMobile ? openMobile : openDesktop}
            onChange={() => {
              setOpenMobile(!openMobile);
              setOpenDesktop(!openDesktop);
            }}
            variant="line-white-yellow"
            className={styles.tocExpandable}
          >
            <Expandable.Title className={styles.tocTitle}>
              {title}
            </Expandable.Title>
            <Expandable.Content className={styles.tocContent}>
              {!!list?.length && (
                <ul>
                  {list.map((item, index) => (
                    <li
                      key={`${item.content}_${index}`}
                      onClick={() => {
                        setOpenMobile(false);
                        setOpenDesktop(false);
                      }}
                    >
                      <Link
                        className={activeUrl === item.url ? styles.active : ''}
                        href={item.url}
                      >
                        {item.content}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </Expandable.Content>
          </Expandable>
        </div>
      </Flex>
    </div>
  );
};

function useIntersectionObserver(
  list: Props['list'],
  setActiveUrl: React.Dispatch<React.SetStateAction<string>>
) {
  useEffect(() => {
    if (!('IntersectionObserver' in window)) return; // Skip if no support
    const observer = new IntersectionObserver(
      (entries) =>
        Array.from(entries)
          .sort((a, b) => a.intersectionRatio - b.intersectionRatio)
          .find((entry) => {
            if (entry.isIntersecting) setActiveUrl(`#${entry.target.id}`);
          }),
      { threshold: 0.8 }
    );

    // Observe all sections
    list.forEach(({ url }) => {
      const section = document.getElementById(url.slice(1));
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [list, setActiveUrl]);
}
