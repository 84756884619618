'use client';
import clsx from 'clsx';
import { Flex, Link, Title } from '@gjensidige/builders-components';
import type { LinkListDoubleProps as Props } from './types';
import styles from './link-list-double.module.css';

export const LinkListDouble = ({
  linkLists,
  backgroundColor = 'dark'
}: Props) => {
  if (linkLists?.length !== 2) return null;

  return (
    <Flex
      center
      className={styles.marginYParts}
      gap="md lg"
      layout={{ xs: 1, sm: 2 }}
    >
      {linkLists.map(({ title, links }, index) => (
        <div
          key={index}
          className={clsx(styles.list, {
            [styles.dark]: backgroundColor === 'dark'
          })}
        >
          <Flex gap="md lg" layout={{ xs: 1, lg: 2 }}>
            <Title size="5" text={title} />
            <ul>
              {links?.map(({ newWindow, text, url }, index) => (
                <li key={index}>
                  <Link href={url} target={newWindow ? '_blank' : undefined}>
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          </Flex>
        </div>
      ))}
    </Flex>
  );
};
