import { useState } from 'react';
import {
  Expandable,
  Spinner,
  Text,
  Title
} from '@gjensidige/builders-components';
import { ArchiveItem } from './archive-item';
import { ArchiveItemProps } from '../types';
import styles from '../archive.module.css';

type Props = {
  items: ArchiveItemProps[];
  isExternalData: boolean;
  isEvent: boolean;
  locale?: string;
};

export const ArchiveList = ({
  items,
  isExternalData,
  isEvent,
  locale
}: Props) => {
  const year = new Date().getFullYear();
  const [openExpandable, setOpenExpandable] = useState<number>(year);
  const yearGrouping = createGroupsByYear(items);

  return (
    <div>
      {items.length ? (
        Object.entries(yearGrouping)
          .sort(([a], [b]) => Number(b) - Number(a))
          .map(([year, group], index) => (
            <Expandable
              key={`${year}-${index}`}
              open={parseInt(year) === openExpandable}
              onChange={() =>
                setOpenExpandable(
                  parseInt(year) != openExpandable ? parseInt(year) : -1
                )
              }
              variant="line-white"
            >
              <Expandable.Title>
                <Title size="5">{year}</Title>
              </Expandable.Title>
              <Expandable.Content className={styles.expandableContent}>
                {group.map((feedItem, index) => (
                  <ArchiveItem
                    key={`${feedItem._id}-${index}`}
                    {...feedItem}
                    isEvent={isEvent}
                    locale={locale}
                  />
                ))}
              </Expandable.Content>
            </Expandable>
          ))
      ) : isExternalData ? (
        <Spinner size="lg" />
      ) : (
        <Text>No articles found</Text>
      )}
    </div>
  );
};

const createGroupsByYear = (items: ArchiveItemProps[]) => {
  const yearGrouping = {} as Record<number, ArchiveItemProps[]>;
  items.forEach((item) => {
    let year;
    if (typeof item.date === 'string') {
      const date = new Date(item.date);
      year = date.getFullYear();
    } else {
      // @ts-ignore
      const date = new Date(item.date?.object || '');
      year = date.getFullYear();
    }

    if (yearGrouping[year]) yearGrouping[year].push(item);
    else yearGrouping[year] = [item];
  });
  return yearGrouping;
};
