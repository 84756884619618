'use client';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Title, Text, Flex, Button } from '@gjensidige/builders-components';
import { ArchiveList } from './components/archive-list';
import { ArchivePartProps } from './types';
import styles from './archive.module.css';

export const Archive = ({
  title,
  text,
  items,
  dataSourceUrl,
  isEvent = false,
  locale
}: ArchivePartProps) => {
  const [itemState, setItemState] = useState(dataSourceUrl ? [] : items);
  const [pagination, setPagination] = useState({
    queryParam: '',
    loading: false
  });

  useEffect(() => {
    if (dataSourceUrl) {
      const getDataFromExtSource = async () => {
        try {
          const res = await fetch(dataSourceUrl);
          const json = await res.json();
          setItemState(json.result);
          if (json.pagination)
            setPagination({ queryParam: json.pagination, loading: false });
        } catch (err) {
          console.log('Error fetching external data', err);
        }
      };
      getDataFromExtSource();
    }
  }, [dataSourceUrl]);

  const fetchPaginatedData = async (paginationUrl: string) => {
    setPagination({ ...pagination, loading: true });
    try {
      const res = await fetch(paginationUrl);
      const json = await res.json();
      if (itemState) setItemState([...itemState, ...json.result]);
      setPagination({ queryParam: json.pagination, loading: false });
    } catch (err) {
      console.log('Error fetching external data', err);
    }
  };

  return itemState ? (
    <Flex
      center
      className={styles.marginBHeaders}
      gap="sm xl"
      layout={{ xs: 1, md: 2 }}
      justify="end"
    >
      <div>
        <Title as="h1" size="3" text={title} />
        {!!text && (
          <Text
            as="div"
            className={clsx(styles.preface, styles.htmlArea)}
            dangerouslySetInnerHTML={{ __html: text }}
            size="large"
          />
        )}
      </div>
      <ArchiveList
        items={itemState}
        isExternalData={Boolean(dataSourceUrl)}
        isEvent={isEvent}
        locale={locale}
      />
      {!!pagination.queryParam && (
        <div>
          <Button
            variant="primary"
            loading={pagination.loading}
            onClick={() =>
              fetchPaginatedData(`${dataSourceUrl}${pagination.queryParam}`)
            }
          >
            {locale === 'en' ? 'Previous years' : 'Tidligere år'}
          </Button>
        </div>
      )}
    </Flex>
  ) : null;
};
