'use client';
import clsx from 'clsx';
import { Button, Flex, Expandable } from '@gjensidige/builders-components';
import { Text, Title } from '@gjensidige/builders-components';
import { ProductActionListProps } from './types';
import styles from './product-action-list.module.css';

const gapTitle = { xs: 'sm', md: 'md' } as const;
const ctaMap = {
  read: 'actionReadMore',
  sales: 'actionPurchase',
  claims: 'actionClaim'
} as const;

const getGroupsLayout = ({ length }: unknown[]) =>
  ({
    xs: 1,
    md: 2,
    xl: length % 3 === 0 || length === 5 ? 3 : 2,
    xxl:
      length % 4 === 0 || length === 7
        ? 4
        : length % 3 === 0 || length == 5
          ? 3
          : 2
  }) as const;

const getColsLayout = ({ length }: unknown[]) =>
  Object.entries({
    xs: 1,
    md: 2,
    xl: length <= 4 ? 2 : length % 3 === 0 ? 3 : 2,
    xxl: length <= 4 ? 2 : length % 4 === 0 ? 4 : length % 3 === 0 ? 3 : 2
  })
    .map(([size, count]) => styles[`cols-${size}-${count}`])
    .join(' ');

export const ProductActionList = ({
  ctaTargets,
  groups,
  hideGroupHeader,
  horizontal,
  textSource,
  title,
  anchor
}: ProductActionListProps) => {
  const textKey = textSource === 'long' ? 'description' : 'uvp';
  const isCols = horizontal || groups.length === 1; // If horizontal or single group - use CSS columns

  return (
    <Flex
      center
      gap={gapTitle}
      layout="1"
      className={styles.marginYParts}
      id={anchor}
    >
      {!!title && <Title as="h2" size="5" uppercase text={title} />}
      <Flex
        align="start"
        gap="xl lg"
        layout={isCols ? 1 : getGroupsLayout(groups)}
      >
        {groups.map(({ actionButton, name, productIcon, products }, index) => (
          <Flex key={index} gap={gapTitle} layout="1">
            {!!name && !hideGroupHeader && groups.length > 1 && (
              <Flex gap="sm" align="start" wrap="nowrap">
                {!!productIcon && (
                  <img alt="" src={productIcon} height={32} width={32} />
                )}
                <Title as={title ? 'h3' : 'h2'} size="5" text={name} />
              </Flex>
            )}
            <div
              className={clsx(
                isCols ? getColsLayout(products || []) : styles['cols-xs-1'],
                Number(products?.length) < 4 &&
                  isCols &&
                  styles.forceSingleColumn
              )}
            >
              {products?.map((product, index, { length }) => (
                <Expandable
                  key={index}
                  variant="white-yellow"
                  className={clsx({
                    [styles.break2]: (index + 1) % Math.ceil(length / 2) === 0, // Break css column after each 1/2 element
                    [styles.break3]: (index + 1) % Math.ceil(length / 3) === 0, // Break css column after each 1/3 element
                    [styles.break4]: (index + 1) % Math.ceil(length / 4) === 0 // Break css column after each 1/4 element
                  })}
                >
                  <Expandable.Title>{product.name}</Expandable.Title>
                  <Expandable.Content>
                    <Flex layout="1" gap="sm">
                      <Text
                        as="div"
                        className={styles.htmlArea}
                        dangerouslySetInnerHTML={{
                          __html: product[textKey] || ''
                        }}
                      />
                      {ctaTargets
                        ?.map(
                          ({ target = '' }) =>
                            product[ctaMap[target as keyof typeof ctaMap]] // Map to relevant product button
                        )
                        .filter((btn) => btn?.url && btn?.text) // Ensure we have both text and target
                        .map((btn, index) => (
                          <div key={index}>
                            <Button
                              href={btn?.url}
                              target={btn?.newWindow ? '_blank' : undefined}
                              rel="noreferrer"
                              variant={index ? 'action' : 'primary'}
                            >
                              {btn?.text}
                            </Button>
                          </div>
                        ))}
                    </Flex>
                  </Expandable.Content>
                </Expandable>
              ))}
            </div>
            {!!actionButton && (
              <div>
                <Button href={actionButton.url} size="sm" variant="action">
                  {actionButton.text}
                </Button>
              </div>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
