'use client';
import { useMemo } from 'react';
import { maybeHTMLorMarkdown } from '../shared/utils';
import { TableMobile } from './components/TableMobile';
import { TableDesktop } from './components/TableDesktop';
import { Check, Close } from '@gjensidige/builders-icons';
import { Button, Flex, Link } from '@gjensidige/builders-components';
import type { ProductInsuranceProps } from '../shared/types';
import type { ProductGroupTableProps } from './types';
import styles from './product-group-table.module.css';

export const ProductGroupTable = ({
  title = '',
  preface = '',
  link,
  group,
  links,
  anchor,
  hideTitle = false
}: ProductGroupTableProps) => {
  const products = group?.products;
  const features = useMemo(() => getAllFeaturesSorted(products), [products]);
  const footer = !!(links?.length || link) && (
    <Flex
      className={styles.footer}
      align="center"
      gap="md xl"
      justify="space-between"
      wrap="wrap"
    >
      {!!links?.length && (
        <Flex gap="xs lg" layout={{ xs: 1, sm: 'auto' }}>
          {links?.map(({ text, url }, index: number) => (
            <div>
              <Link href={url} key={index}>
                {text}
              </Link>
            </div>
          ))}
        </Flex>
      )}
      {!!link && (
        <Button
          variant="primary"
          href={link.url}
          tracking={!link.trackingLabel || { eventLabel: link.trackingLabel }}
        >
          {link.text}
        </Button>
      )}
    </Flex>
  );

  const props = { title, hideTitle, preface, products, features, footer };

  return (
    <Flex center layout="1" className={styles.marginYParts} id={anchor}>
      <TableDesktop {...props} />
      <TableMobile {...props} />
    </Flex>
  );
};

function getAllFeaturesSorted(products: ProductInsuranceProps[] = []) {
  const all = new Map(); // Use Map to keep order, but also quickly detect duplicates
  products
    .slice() // Make clone to prevent sorting original array
    .sort((a, b) => (b.features?.length || 0) - (a.features?.length || 0)) // Sort by most features
    .forEach(({ features }) =>
      features?.map((feature) =>
        all.set(feature.name, {
          ...feature,
          uvp: maybeHTMLorMarkdown(feature.uvp)
        })
      )
    );
  return Array.from(all.values()); // Convert Map to Array
}

// eslint-disable-next-line react-refresh/only-export-components
export function getCoverage(
  featureName: string,
  product: ProductInsuranceProps
) {
  const isCovered = product?.features?.find(({ name }) => name === featureName);

  return {
    isCovered,
    text: isCovered?.conditionText || '',
    icon: isCovered ? (
      <Check
        aria-label={product.coverageTexts?.covered}
        aria-hidden="false" // aria-hidden="false" needed to overwrite defaults from @gjensidige/builders-icons
      />
    ) : (
      <Close
        aria-label={product.coverageTexts?.notCovered}
        aria-hidden="false"
      />
    )
  };
}
