import { useContext } from 'react';
import type { NavItem } from '../types';
import * as iconsModule from '@gjensidige/builders-icons';
import { Nav } from '@gjensidige/core-navigation';

// Enable refering to icons by lowercase names
const icons = Object.keys(iconsModule).reduce(
  (all: Record<string, unknown>, name: string) => {
    all[name.toLowerCase()] = iconsModule[name as keyof typeof iconsModule];
    return all;
  },
  {}
);

type Props = {
  currentPath?: string;
  homeUrl?: string;
  items?: Array<NavItem>;
  notifications?: Record<string, string | number | undefined>;
};

export const ApiToNavItems = ({
  currentPath = '',
  homeUrl,
  items,
  notifications = {}
}: Props): JSX.Element => {
  const isHome = currentPath === homeUrl;
  const isMobile = useContext(Nav.isMobile);
  const texts = useContext(Nav.language);

  return (
    <>
      {items?.map((item, index: number) => {
        const isParent = item.children?.length;
        const Icon =
          item.icon &&
          (icons[item.icon] as (
            props: React.SVGProps<SVGSVGElement>
          ) => JSX.Element);
        const Item = homeUrl ? Nav.CMS : Nav.Item;
        const rest = {} as {
          open?: boolean;
          href?: string;
        };

        if (isMobile && !isHome) rest['open'] = item.isExpanded; // Auto expand mobile
        if (!isMobile || !isParent) rest['href'] = item.href; // Prevent href if mobile toggle for children
        if (!isMobile && homeUrl) rest['open'] = item.isExpanded; // Auto expand desktop

        return (
          <Item
            selected={!!item.isExpanded}
            aria-current={item.href === currentPath}
            aria-disabled={item.private || undefined}
            badge={
              item.notificationsKey && notifications[item.notificationsKey]
            }
            icon={Icon && <Icon />}
            key={`${item.label}${index}${isMobile}`}
            label={item.label}
            {...rest}
          >
            {isParent ? (
              <>
                {!item.href || !isMobile || (
                  <Nav.Item
                    href={item.href}
                    label={`${texts.gotofront} ${item.label}`}
                  />
                )}
                <ApiToNavItems
                  currentPath={currentPath}
                  items={item.children}
                  notifications={notifications}
                />
                {item.ctas?.map(({ label, href }, index: number) => (
                  <Nav.CTA key={`${label}${index}`} href={href} label={label} />
                ))}
              </>
            ) : null}
          </Item>
        );
      })}
    </>
  );
};
