import { initializeFaro } from '@grafana/faro-web-sdk';
import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { clientIdentification } from '@gjensidige/service-client-identification';
import * as Parts from './index';

const toPascalCase = (snakeCase: string) =>
  snakeCase.replace(/^.|-./g, (m) => m.slice(-1).toUpperCase());

const faroSelector = 'meta[name="builders-faro-config"]';
const faroMeta = document.querySelector<HTMLMetaElement>(faroSelector);
const faroConfig = JSON.parse(faroMeta?.content || 'null');
const isLocalhost = window.location.host.includes('localhost');

if (!isLocalhost && faroConfig)
  initializeFaro({
    ...faroConfig,
    sessionTracking: {
      enabled: true,
      generateSessionId: () => clientIdentification.getSessionId()
    }
  });

document.querySelectorAll('[data-part][data-props]').forEach((domNode) => {
  const part = toPascalCase(domNode.getAttribute('data-part') || '');
  const props = JSON.parse(domNode.getAttribute('data-props') || 'null');
  const Part = Parts[part as keyof typeof Parts] as React.FunctionComponent;
  domNode.removeAttribute('data-props'); // Prevent double initialization

  if (Part) hydrateRoot(domNode, createElement(Part, props), {});
  else console.error(`Could not load part ${part}`);
});
