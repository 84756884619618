'use client';
import clsx from 'clsx';
import {
  Button,
  Flex,
  ScreenReaderOnly,
  Text,
  Title
} from '@gjensidige/builders-components';
import type { HeaderIllustratedProps as Props } from './types';
import styles from './header-illustrated.module.css';

export const HeaderIllustrated = ({
  h1,
  buttons,
  altText,
  illustration,
  text,
  title
}: Props) => (
  <Flex
    align="center"
    as="header"
    center
    className={clsx(styles.headerIllustrated, styles.marginBHeaders)}
    gap="md xl"
    layout={{ xs: 1, lg: 2 }}
  >
    {!!h1 && <ScreenReaderOnly as="h1">{h1}</ScreenReaderOnly>}
    {!!illustration && (
      <div className={styles.illustration}>
        <img alt={altText || ''} src={illustration} />
      </div>
    )}
    <Flex layout="1" className={styles.content} gap={{ xs: 'xs none' }}>
      {!!title && (
        <Title
          as={h1 ? 'h2' : 'h1'}
          className={styles.title}
          size="3"
          text={title}
        />
      )}
      {!!text && (
        <Text
          as="div"
          className={styles.htmlArea}
          dangerouslySetInnerHTML={{ __html: text }}
          data-testid="text"
          size="large"
        />
      )}
      {!!buttons?.length && (
        <Flex gap="sm md" className={styles.buttons}>
          {buttons.map(({ text, url, trackingLabel, newWindow }, index) => (
            <Button
              href={url}
              target={newWindow ? '_blank' : undefined}
              rel="noreferrer"
              key={index}
              tracking={!!trackingLabel && { eventLabel: trackingLabel }}
              variant={
                buttons?.length === 1
                  ? 'critical'
                  : index
                    ? 'secondary'
                    : 'primary'
              }
            >
              {text}
            </Button>
          ))}
        </Flex>
      )}
    </Flex>
  </Flex>
);
