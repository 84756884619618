import { useContext, useEffect, useId, useMemo, useRef, useState } from 'react';
import type { SearchProps, SearchItemProps } from './types';
import { trackEvent, TrackingContext } from '@gjensidige/core-analytics';
import {
  Flex,
  Input,
  Link,
  Loader,
  Pagination,
  ScreenReaderOnly,
  Toggles,
  Text,
  Title,
  TogglesProps
} from '@gjensidige/builders-components';
import { ArrowRight } from '@gjensidige/builders-icons';
import styles from './search.module.css';

type States = 'idle' | 'loading' | SearchItemProps[];

let AJAX: XMLHttpRequest;
let DEBOUNCE: ReturnType<typeof setTimeout> | number;
const PAGE_SIZE = 10;
const TRACKING = {
  eventAction: 'search',
  eventCategory: 'site-search',
  eventKey: 'input'
};

export const Search = ({
  action,
  inputId,
  phrases = {},
  query = '',
  searchAPIUrl,
  segments = []
}: SearchProps) => {
  const [filter, setFilter] = useState<TogglesProps['value']>(0);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(query);
  const [state, setState] = useState<States>('idle'); // Initial state is before the user has searched
  const prevQuery = useRef(searchQuery);
  const track = useContext(TrackingContext);
  const filters = useMemo(() => {
    const all = { text: phrases.labels?.segmentOptionAll, url: '' };
    return [all, ...segments].filter(({ text }) => text?.trim()); // Only show filters with a text label
  }, [phrases, segments]);

  const isResults = Array.isArray(state);
  const products = isResults ? state.filter(({ product }) => product) : [];
  const others = isResults ? state.filter(({ product }) => !product) : [];
  const pages = Math.ceil(others.length / PAGE_SIZE);
  const total = isResults ? state.length : 0;

  const id = useId();

  // Fetch results on state change
  useEffect(() => {
    const shouldSearch = searchAPIUrl && searchQuery.length >= 2;
    const debounce = prevQuery.current === searchQuery ? 0 : 2000; // Debounce only if query has changed
    prevQuery.current = searchQuery; // Always update prevQuery to reset debounce

    clearTimeout(DEBOUNCE);
    setState(shouldSearch ? 'loading' : 'idle');
    if (AJAX) AJAX.abort();
    else AJAX = new XMLHttpRequest();

    if (shouldSearch)
      DEBOUNCE = setTimeout(() => {
        const segmentUrl = filters[Number(filter)]?.url || '';
        const exclude = filters
          .map(({ url }) => url)
          .map(stripLeadingSlash)
          .filter(Boolean);

        AJAX.open('POST', searchAPIUrl, true);
        AJAX.setRequestHeader('Content-Type', 'application/json');
        AJAX.onload = () => {
          const data = JSON.parse(AJAX.responseText);
          const eventStatus = data?.length ? 'success' : 'error';
          const eventLabel = searchQuery;
          setPage(1); // Reset ITEMS_TO_SHOW when doing a new search
          setState(data);
          trackEvent({ ...TRACKING, eventLabel, eventStatus }, track.options);
        };
        AJAX.send(
          JSON.stringify({
            query: searchQuery,
            filter: segmentUrl === '/' ? '' : segmentUrl,
            excludeFilter: segmentUrl === '/' ? exclude : []
          })
        );
      }, debounce);
  }, [filter, filters, searchAPIUrl, searchQuery, track]);

  // Prevent visual jumps
  useEffect(() => {
    const form = document.getElementById('search-form');
    if (form)
      form.style.minHeight =
        Math.min(window.innerHeight, form.clientHeight) + 'px';
  }, [total]);

  return (
    <Flex
      action={action?.href}
      alignContent="start"
      as="form"
      gap="xl"
      id="search-form"
      layout="1"
      method="get"
      onSubmit={(event: React.FormEvent) => event.preventDefault()}
      role="search"
    >
      <Flex layout="1" gap="sm">
        <Title as="label" htmlFor={inputId || id} size="4">
          {phrases.labels?.input || 'Search'}
        </Title>
        <Input
          autoComplete="off"
          autoFocus
          id={inputId || id}
          name={inputId || id}
          onChange={({ target }) => setSearchQuery(target.value)}
          tracking={false}
          type="search"
          value={searchQuery}
        />
        <Toggles
          fill
          value={filter}
          onChange={setFilter}
          aria-label={phrases.translate?.filterHits || 'Filter hits'}
        >
          {filters.map(({ text }, index) => (
            <Toggles.Toggle key={index} value={index}>
              {text}
            </Toggles.Toggle>
          ))}
        </Toggles>
        <ScreenReaderOnly aria-live="polite">
          {state === 'loading'
            ? phrases.translate?.loadingResults
            : isResults
              ? `${phrases.translate?.searchResults} ${total}`
              : ''}
        </ScreenReaderOnly>
      </Flex>
      {!!products.length && (
        <Flex layout="1" gap="sm">
          <Title as="h2" size="body" uppercase>
            {`${phrases.labels?.products || 'Products'} (${products.length})`}
          </Title>
          <Flex gap={{ xs: 'sm', md: 'sm' }}>
            {products.map(({ product, link: { url } }) => (
              <Link className={styles.product} href={url} key={url}>
                {product}
              </Link>
            ))}
          </Flex>
        </Flex>
      )}

      {!!others.length && (
        <div>
          <Title as="h2" size="body" uppercase>
            {`${products.length ? phrases.labels?.otherResults || 'Search results' : phrases.translate?.otherResults || 'Hits'} (${others.length})`}
          </Title>
          {others
            .slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page)
            .map(({ title, preface, link }, index) => (
              <Flex className={styles.result} gap="xs" key={index} layout="1">
                <Link href={link.url}>
                  <Title as="h3" size="6" text={title} />
                </Link>
                {!!preface && (
                  <Text size="small" className={styles.preface}>
                    {preface}
                  </Text>
                )}
                <Flex
                  align="center"
                  aria-label={phrases.translate?.breadcrumbs}
                  as="ul"
                  className={styles.breadcrumbs}
                  gap="xs"
                  role="list"
                >
                  {breadCrumbGenerator(link.url)}
                </Flex>
              </Flex>
            ))}
        </div>
      )}

      {state === 'loading' && (
        <Flex gap="xs" layout="1">
          <Loader variant="skeleton" size="24" style={{ maxWidth: '70%' }} />
          <Loader variant="skeleton" size="24" />
        </Flex>
      )}

      {state === 'idle' && (
        <Text as="div">
          <strong>{phrases.information?.title || ''}</strong>
          <Text>{phrases.information?.text || ''}</Text>
        </Text>
      )}

      {pages > 1 && (
        <Pagination
          aria-label={phrases.translate?.pageNavigation || 'Page navigation'}
          className={styles.pagination}
          nextLabel="→"
          previousLabel="←"
          max={pages}
          value={page}
          onChange={(page) => {
            setPage(page);
            setTimeout(() => {
              const itemSelector = `.${styles.result} a`;
              const item = document.querySelector<HTMLElement>(itemSelector);
              item?.scrollIntoView({ behavior: 'smooth' });
              item?.focus();
            }, 100);
          }}
        />
      )}

      {isResults && !total && (
        <Text
          as="div"
          className={styles.htmlArea}
          dangerouslySetInnerHTML={{
            __html: `<strong>${phrases.emptyResult?.title || ''}</strong>${phrases.emptyResult?.text || ''}`
          }}
        />
      )}
    </Flex>
  );
};

const breadCrumbGenerator = (target: string) =>
  stripLeadingSlash(target)
    .replace(/-/g, ' ')
    .split('/')
    .map((title) => title.slice(0, 1).toUpperCase() + title.slice(1))
    .map((crumb: string, index: number) => (
      <Flex
        align="center"
        as="li"
        gap="xs"
        key={`${crumb}-${index}`}
        role="listitem"
      >
        {!!index && <ArrowRight />}
        {crumb}
      </Flex>
    ));

const stripLeadingSlash = (str = '') =>
  str.startsWith('/') ? str.slice(1) : str;
