'use client';
import { Button, Flex, Link, Title } from '@gjensidige/builders-components';
import type { ProductButtonsProps as Props } from './types';
import styles from './product-buttons.module.css';

export const ProductButtons = ({
  title,
  buttons,
  compact = false,
  showIcons = true,
  links
}: Props) =>
  buttons.length ? (
    <Flex
      align="start"
      as="section"
      center
      className={styles.marginYParts}
      gap="sm xl"
      layout={{ xs: 1, md: compact ? 1 : 2 }}
    >
      <Title size={compact ? 5 : 3} uppercase={compact} text={title} />
      <Flex layout="1" gap="sm">
        <Flex
          className={styles.buttons}
          gap={{ xs: 'sm xs', md: 'sm' }}
          wrap={compact ? 'nowrap' : 'wrap'}
        >
          {buttons?.map(({ icon, target, trackingLabel, text }, index) => (
            <Link
              className={styles.button}
              href={target}
              key={index}
              tracking={{
                eventAction: 'click',
                eventCategory: 'link',
                eventKey: 'button',
                eventLabel: trackingLabel ?? `product-buttons-module_${target}`,
                eventModule: 'product-buttons-module',
                eventSource: '@gjensidige/parts-product-buttons',
                eventVersion: '1.0.0'
              }}
            >
              {!!icon && showIcons && <img src={icon} alt="" loading="lazy" />}
              {text}
            </Link>
          ))}
        </Flex>
        {!!links?.length && (
          <Flex gap={{ xs: 'sm md', md: 'sm xl' }}>
            {links?.filter(Boolean).map((link, index) => (
              <Button
                href={link?.url}
                key={index}
                variant="action"
                tracking={{
                  eventLabel:
                    link?.trackingLabel || `product-buttons-module_${link?.url}`
                }}
              >
                {link?.text}
              </Button>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  ) : null;
