'use client';
import { Button, Card, Flex, Title } from '@gjensidige/builders-components';
import type { LinkListIconsProps, ProductCardsProps } from './types';
import styles from './link-list-icons.module.css';

type Props = LinkListIconsProps & ProductCardsProps;

export const LinkListIcons = ({
  title: heading,
  cards,
  noBackgroundColor = false, // link list
  actionButton // product cards
}: Props) => {
  if (!cards?.length) return null;

  const count = cards.length;
  const oddNumbers = count % 2 && count !== 7;
  const lg = oddNumbers || count === 6 ? 3 : 2;
  const xl = count === 4 || count === 7 || count === 8 ? 4 : lg;

  return (
    <Flex
      as="section"
      center
      layout="1"
      gap={{ xs: 'sm xl', md: 'sm xl' }}
      className={styles.marginYParts}
    >
      {!!heading && <Title size="5" text={heading} uppercase />}
      <Flex
        gap={{ xs: 'sm xl', md: 'xl' }}
        layout={{ xs: 1, md: 2, lg: lg, xl: xl }}
      >
        {cards.map(({ title, text, icon, target, trackingLabel }, index) => (
          <Card
            arrow={icon ? false : 'under'}
            href={target}
            key={index}
            tracking={{ eventLabel: trackingLabel || title }}
            variant={
              icon
                ? noBackgroundColor
                  ? 'white-yellow'
                  : 'blue-yellow'
                : 'blue-blue'
            }
            icon={!!icon && <img src={icon} width="32" height="32" alt="" />}
          >
            <Title as={heading ? 'h3' : 'h2'} size="5" text={title} />
            {/*Render with title to achieve automatic string break formatting */}
            <Title as="div" size="6" className={styles.text} text={text} />
          </Card>
        ))}
      </Flex>
      {!!actionButton && (
        <div className={styles.actionButton}>
          <Button variant="action" size="sm" href={actionButton.url}>
            {actionButton.text}
          </Button>
        </div>
      )}
    </Flex>
  );
};
