'use client';
import clsx from 'clsx';
import {
  Button,
  Expandable,
  Text,
  Title,
  Flex
} from '@gjensidige/builders-components';
import { Contact, Info, Image, Quote, Tip } from './aside';
import type { ArticleSectionProps } from './types';
import styles from './article-section.module.css';

export function ArticleSection({
  title,
  text,
  buttons,
  tip,
  info,
  tableOfContents,
  expandable,
  quote,
  contact,
  image,
  tipInfoPlacement = 'left'
}: ArticleSectionProps) {
  const aside = tip || info || quote || image || contact;
  const placement = aside?.placement || 'top';
  const align = { top: 'start', middle: 'center', bottom: 'end' } as const;
  const anchor = String(tableOfContents?.anchor || title || '')
    .toLowerCase()
    .replace(/\s/gi, '-')
    .replace(/[^a-zA-Z0-9-]/gi, '');

  const renderTitle = !!title && <Title size="4" text={title} />;
  const renderAside = (
    <div
      className={clsx(styles.aside, {
        [styles.notStackBetween]: !(tip || info)
      })}
    >
      {(contact && <Contact {...contact} />) ||
        (image && <Image {...image} />) ||
        (info && <Info {...info} />) ||
        (quote && <Quote {...quote} />) ||
        (tip && <Tip {...tip} />)}
    </div>
  );

  return (
    <Flex
      align={align[placement]}
      center
      className={clsx(styles[placement], styles.marginYParts, {
        [styles.pushTitle]: title
      })}
      gap={{ xs: 'sm', md: 'sm xl' }}
      id={anchor || undefined}
      direction={tipInfoPlacement === 'left' ? 'row' : 'row-reverse'}
      layout={{ xs: 1, md: 2 }}
    >
      {placement === 'top' && renderTitle}
      {placement === 'top' && renderAside}
      <Flex layout={1}>
        {placement !== 'top' && renderTitle}
        {!!text && (
          <Text
            as="div"
            dangerouslySetInnerHTML={{ __html: text }}
            className={clsx(styles.htmlArea, {
              [styles.hasWideTable]: !aside
            })}
          />
        )}
        {!!buttons?.length && (
          <Flex className={styles.mt1}>
            {buttons?.map((button, index) => (
              <Button
                key={`${button.text}_${index}`}
                variant={index === 0 ? 'primary' : 'secondary'}
                target={button.newWindow ? '_blank' : undefined}
                href={button.url}
              >
                {button.text}
              </Button>
            ))}
          </Flex>
        )}
      </Flex>
      {placement !== 'top' && renderAside}
      {!!expandable?.length && (
        <>
          <div />
          <Flex direction="column">
            {expandable.map((exp, index) => (
              <Expandable key={index}>
                <Expandable.Title>{exp.title}</Expandable.Title>
                <Expandable.Content>
                  <Text
                    as="div"
                    className={styles.htmlArea}
                    dangerouslySetInnerHTML={{ __html: exp.text || '' }}
                  />
                </Expandable.Content>
              </Expandable>
            ))}
          </Flex>
        </>
      )}
    </Flex>
  );
}
