'use client';
import { useState } from 'react';
import clsx from 'clsx';
import {
  Flex,
  Table as CoreTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Title,
  Tr
} from '@gjensidige/builders-components';
import type { Table as Props } from './types';
import { useTableData } from '../shared/utils';
import styles from './table.module.css';

export const Table = ({
  isArticlePage,
  data = '',
  isLarge = true,
  text,
  title,
  anchor
}: Props) => {
  const table = useTableData(data);
  const [expand, setExpand] = useState<Record<number, boolean>>({});
  const classes = clsx(styles.marginYParts, {
    [styles.isArticlePage]: isArticlePage,
    [styles.isLarge]: isLarge,
    [styles.hasText]: text
  });

  // Used to remember expand when looping through rows
  let isExpandHead = false;
  let isExpandBody = false;

  return (
    <Flex
      center
      className={classes}
      gap="sm xl"
      layout={{ xs: 1, lg: 2 }}
      id={anchor}
    >
      <Flex layout="1" className={clsx(styles.content, styles.htmlArea)}>
        {!!title && <Title as="h2" size="4" text={title} />}
        {!!text && <Text as="div" dangerouslySetInnerHTML={{ __html: text }} />}
      </Flex>
      <div className={styles.scroller}>
        {/* Render table from server in case JS breaks */}
        <div dangerouslySetInnerHTML={table ? undefined : { __html: data }} />
        <CoreTable>
          {!!table?.head.length && (
            <Thead>
              {table.head.map((cells, row) => (
                <Tr key={row}>
                  {cells.map(({ props }) => (
                    <Th {...props} />
                  ))}
                </Tr>
              ))}
            </Thead>
          )}
          <Tbody>
            {table?.body.map((cells, row, rows) => {
              isExpandBody = isExpandHead;
              isExpandHead =
                !isExpandBody && // We're not currently on a expand body
                cells.length > 1 && // And current row has more than one cell
                rows[row + 1]?.length === 1 && // And next row has only one cell
                rows[row + 1]?.[0].props.colSpan >= cells.length; // And next row's cell spans all current row cells

              return (
                <Tr
                  key={row}
                  className={clsx(isExpandHead && styles.isExpandHead)}
                  hidden={isExpandBody ? !expand[row - 1] : undefined}
                  onClick={() => setExpand({ ...expand, [row]: !expand[row] })}
                >
                  {cells.map(({ nodeName, props: immutableProps }, cell) => {
                    const Tag = nodeName === 'TD' || isExpandBody ? Td : Th;
                    const props = { ...immutableProps }; // Ensure we always work with a copy to not edit the original

                    if (isExpandHead && cell === 0)
                      props.children = (
                        <CoreTable.Expander expanded={expand[row]} icon>
                          {props.children}
                        </CoreTable.Expander>
                      );

                    return <Tag {...props} />;
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </CoreTable>
      </div>
    </Flex>
  );
};
