'use client';
import { useEffect, useRef } from 'react';
import { Flex } from '@gjensidige/builders-components';
import type { TickerProps as Props } from './types';
import styles from './ticker.module.css';

export const Ticker = ({ text }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !document.scrollingElement) return;
    const element = ref.current;
    const scrollEl = document.scrollingElement as HTMLElement;
    const repaint = () => {
      const { scrollY, innerHeight: vh } = window;
      const { bottom, width } = element.getBoundingClientRect();
      const lowerBound = Math.max(0, bottom + scrollY - vh);
      const upperBound = Math.min(scrollEl.scrollHeight - vh, bottom + scrollY);
      const percent = (scrollY - lowerBound) / (upperBound - lowerBound);

      element.style.translate = `${-Math.round(percent * width)}px`;
    };

    repaint();
    window.addEventListener('scroll', repaint);
    window.addEventListener('resize', repaint);
    return () => {
      window.removeEventListener('scroll', repaint);
      window.removeEventListener('resize', repaint);
    };
  }, []);

  return (
    <Flex center className={styles.ticker}>
      <div ref={ref}>{text}</div>
    </Flex>
  );
};
