import { useState } from 'react';
import { getCoverage } from '../product-group-table';
import {
  Box,
  Card,
  Expandable,
  Flex,
  Text,
  Title,
  Toggles
} from '@gjensidige/builders-components';
import type { ProductGroupTableViewProps } from '../types';
import type { TogglesProps } from '@gjensidige/builders-components';
import styles from '../product-group-table.module.css';

export const TableMobile = ({
  features,
  footer,
  preface,
  products,
  title,
  hideTitle
}: ProductGroupTableViewProps) => {
  const [filter, setFilter] = useState<TogglesProps['value']>(0);
  const product = products?.[Number(filter)];

  return (
    <Card className={styles.mobile}>
      <Box as="header">
        {!hideTitle && !!title && <Title size="4" text={title} />}
        {!!preface && (
          <Text
            as="div"
            className={styles.htmlArea}
            dangerouslySetInnerHTML={{ __html: preface }}
          />
        )}
      </Box>

      <Toggles
        variant="white-grouped"
        value={filter}
        className={styles.toggles}
        onChange={setFilter}
      >
        {products?.map(({ name }, index: number) => (
          <Toggles.Toggle key={`${name}-${index}`} value={index}>
            {name}
          </Toggles.Toggle>
        ))}
      </Toggles>

      {!!product && (
        <>
          <Box>
            <Flex layout="1" gap="xs">
              <Title as="h3" size="5" text={product.name} />
              {!!product.uvp && <Text>{product.uvp}</Text>}
            </Flex>
          </Box>
          {features.map(function Row({ name, uvp }, index: number) {
            const { icon, text, isCovered } = getCoverage(name, product);

            return (
              <Expandable
                aria-disabled={!isCovered} // Hide not covered features for better VoiceOver-experience
                key={`${name}-${index}`}
                variant={`line-${isCovered ? 'blue' : 'white'}-yellow`}
              >
                <Expandable.Title className={styles.expandableTitle}>
                  <Flex gap="xs" align="start" wrap="nowrap">
                    {icon}
                    <div>
                      {name}
                      {!!text && ` (${text})`}
                    </div>
                  </Flex>
                </Expandable.Title>
                <Expandable.Content>{uvp}</Expandable.Content>
              </Expandable>
            );
          })}
        </>
      )}
      {!!footer && <Box as="footer">{footer}</Box>}
    </Card>
  );
};
