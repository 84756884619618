'use client';
import { TrustpilotLogo, TrustpilotStars } from '../shared/trustpilot';
import {
  Flex,
  Title,
  Text,
  Expandable,
  Link
} from '@gjensidige/builders-components';
import type { TrustpilotProps as Props } from './types';
import styles from './trustpilot.module.css';
import clsx from 'clsx';

export const Trustpilot = ({
  locale,
  stars,
  review,
  profileUrl,
  disclaimer
}: Props) => (
  <div className={styles.marginYParts}>
    <Flex
      center
      className={clsx(styles.trustpilot, {
        [styles.hasReviews]: !!review
      })}
      fill
      gap="lg xl"
      direction="column-reverse"
      layout={{ xs: 1, md: 2, lg: 7.5 }}
    >
      {!!review && (
        <Flex as="blockquote" direction="column" gap="sm">
          {<Text size="lead">{review && `"${review.text}"`}</Text>}
          <Link
            href={review.url}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.reviewLink}
          >
            <Title as="cite" size="5" text={review?.reviewer} />
          </Link>
        </Flex>
      )}
      <Flex align="center" gap="xs md">
        <Flex align="center" gap={{ xs: 'sm', lg: 'md' }}>
          <Title as="div" size="3" style={{ marginBottom: '-.25em' }}>
            {stars.trustScore.toLocaleString(locale)} {stars.translate.of} 5
          </Title>
          <Link
            aria-hidden="true"
            className={styles.stars}
            href={profileUrl}
            rel="noreferrer noopener"
            target="_blank"
            icon={false}
          >
            <TrustpilotStars score={stars.trustScore} />
          </Link>
        </Flex>
        <Text size="lead">
          {stars.translate.basedOn}{' '}
          <strong>
            {stars.numberOfReviews.toLocaleString(locale)}{' '}
            {stars.translate.reviews}
          </strong>
        </Text>
        <Link
          href={profileUrl}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.trustpilotLogo}
          icon={false}
        >
          <TrustpilotLogo />
        </Link>
      </Flex>
    </Flex>
    {!!(disclaimer?.title && disclaimer?.text) && (
      <Flex center layout="1" className={styles.disclaimer}>
        <Expandable variant="blue-white" defaultOpen={true}>
          <Expandable.Title>{disclaimer.title}</Expandable.Title>
          <Expandable.Content>
            <Text
              as="div"
              className={styles.htmlArea}
              dangerouslySetInnerHTML={{ __html: disclaimer.text }}
            />
          </Expandable.Content>
        </Expandable>
      </Flex>
    )}
  </div>
);
