'use client';
import { useMemo } from 'react';
import { Flex, ScreenReaderOnly } from '@gjensidige/builders-components';
import { Search } from '../shared/search';
import type { SearchProps } from '../shared/types';
import styles from '../shared/utils.module.css';

export const PageSearch = (props: SearchProps) => {
  const query = useMemo(() => {
    const params = typeof window === 'undefined' ? '' : window.location.search;

    return new URLSearchParams(params).get('q') || '';
  }, []);

  return (
    <Flex center="md" className={styles.marginBHeaders} layout="1">
      <ScreenReaderOnly as="h1">{props.pageTitle}</ScreenReaderOnly>
      <Search {...props} inputId="q" query={query} />
    </Flex>
  );
};
