'use client';
import { useState, useRef, useEffect } from 'react';
import { Play, Pause } from '@gjensidige/builders-icons';
import {
  Button,
  Title,
  Flex,
  ScreenReaderOnly
} from '@gjensidige/builders-components';
import type { HeaderFeatureProps as Props } from './types';
import styles from './header-feature.module.css';
import clsx from 'clsx';

const SIZES = `(min-width: 1024px) and (max-width: 1199px) 1024px, (min-width: 1200px) and (max-width: 1619px) 1200px, (min-width: 1620px) 2200px, 100vw`;

export const HeaderFeature = ({
  h1,
  title,
  image,
  buttons,
  mobileImage,
  video,
  mobileVideo
}: Props) => {
  const [play, setPlay] = useState(true);
  const [showVideo, setShowVideo] = useState(!!video || !!mobileVideo);
  const [loaded, setLoaded] = useState(false); // Used to hide gradient while loading
  const desktopVideoRef = useRef<HTMLVideoElement>(null);
  const mobileVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const reduce = window.matchMedia?.('(prefers-reduced-motion: reduce)');
    const prefersReducedMotion = reduce?.matches;
    const isMobile = mobileVideoRef.current?.offsetWidth;
    const videoRef = isMobile ? mobileVideoRef : desktopVideoRef;

    videoRef.current?.[play ? 'play' : 'pause']();
    setShowVideo(!prefersReducedMotion);
  }, [play, video]);

  return (
    <Flex
      align="end"
      as="header"
      className={clsx(
        styles.headerFeature,
        styles.marginBHeaders,
        styles[loaded ? 'isLoaded' : 'isLoading']
      )}
    >
      {!!h1 && <ScreenReaderOnly as="h1">{h1}</ScreenReaderOnly>}
      {mobileVideo && showVideo ? (
        <video
          aria-hidden="true"
          aria-label={image?.alt || ''}
          autoPlay
          className={styles.videoMobile}
          loop
          muted
          onLoadedData={() => setLoaded(true)}
          playsInline
          preload="metadata"
          ref={mobileVideoRef}
        >
          <source src={mobileVideo} type="video/mp4" />
        </video>
      ) : (
        <img
          alt={mobileImage?.alt || image?.alt || ''}
          onLoad={() => setLoaded(true)}
          sizes={SIZES}
          src={mobileImage?.url || image?.url}
          srcSet={mobileImage?.srcset || image?.srcset}
        />
      )}
      {video && showVideo ? (
        <video
          aria-hidden="true"
          aria-label={image?.alt || ''}
          autoPlay
          className={styles.videoDesktop}
          loop
          muted
          onLoadedData={() => setLoaded(true)}
          playsInline
          preload="metadata"
          ref={desktopVideoRef}
        >
          <source src={video} type="video/mp4" />
        </video>
      ) : (
        <img
          alt={image?.alt || ''}
          hidden
          onLoad={() => setLoaded(true)}
          sizes={SIZES}
          src={image?.url}
          srcSet={image?.srcset}
        />
      )}
      <Flex center layout="1">
        <Flex layout={{ xs: 1, md: 8.4, xl: 7.5 }} gap={{ xs: 'xs', md: 'md' }}>
          {!!title && (
            <Title
              as={h1 ? 'h2' : 'h1'}
              className={styles.title}
              size="2"
              text={title}
              uppercase
            />
          )}
        </Flex>
        <Flex gap={{ xs: 'xs sm', md: 'md' }}>
          {buttons?.map(({ text, url }, index, { length }) => (
            <Button
              data-theme="dark"
              key={index}
              href={url}
              variant={
                length === 1 ? 'critical' : index ? 'secondary' : 'primary'
              }
            >
              {text}
            </Button>
          ))}
          {showVideo && (
            <Button
              aria-hidden="true"
              className={styles.playPause}
              data-desktop-video={!!video}
              data-mobile-video={!!mobileVideo}
              icon={play ? <Pause /> : <Play />}
              onClick={() => setPlay(!play)}
              variant="unset"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
