'use client';
import clsx from 'clsx';
import { Flex, Link, Text, Title } from '@gjensidige/builders-components';
import type { LinkListIllustratedProps as Props } from './types';
import styles from './link-list-illustrated.module.css';

export const LinkListIllustrated = ({
  title,
  links,
  description,
  symbol,
  listPlacement = 'right',
  backgroundColor = 'light',
  longDescription = false
}: Props) => {
  if (!links?.length) return null;
  return (
    <Flex
      center
      layout={{ xs: 1, md: symbol ? 2 : 1 }}
      gap="sm xl"
      align="center"
      direction={listPlacement === 'left' ? 'row-reverse' : 'row'}
      className={clsx(styles.marginYParts, styles.linkList, {
        [styles.dark]: backgroundColor === 'dark'
      })}
    >
      {!!symbol && (
        <img
          src={symbol}
          height="250"
          width="250"
          alt=""
          className={styles.symbol}
          loading="lazy"
        />
      )}

      <Flex
        layout={{ xs: 1, md: symbol ? 1 : 2 }}
        gap={{ xs: 'sm xl', md: 'xs xl' }}
        direction={listPlacement === 'left' ? 'row-reverse' : 'row'}
      >
        <Title size="3" text={title} />
        <Flex layout="1" gap="sm">
          {!!description && (
            <Text
              as="div"
              className={styles.htmlArea}
              dangerouslySetInnerHTML={{ __html: description }}
              size={longDescription ? 'body' : 'large'}
            />
          )}
          <ul className={styles.links}>
            {links.map(({ text, url, newWindow }, index, { length }) => (
              <li
                key={index}
                className={clsx({
                  [styles.half]: index === Math.floor((length - 1) / 2)
                })}
              >
                <Link href={url} target={newWindow ? '_blank' : undefined}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </Flex>
      </Flex>
    </Flex>
  );
};
